@import '../../../index.scss';

.PropertiesCom {
  position: relative;
  padding: 30px 30px 100px 0;
  .CompDiv {
    position: relative;
    align-items: flex-start;
    .LeftSideBar {
      width: 100px;
      background: nth($color, 1);
      box-shadow: 0 0px 6px rgba(147, 39, 143, 0.2);
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      overflow: hidden;
      margin-top: 170px;
      li {
        position: relative;
        a {
          display: block;
          text-align: center;
          padding: 30px;
          &.active {
            background: rgba(147, 39, 143, 0.2);
          }
          &:hover {
            background: rgba(147, 39, 143, 0.2);
          }
        }
      }
    }
    .MainComponent {
      width: 1140px;
      @include Media(1600) {
        width: 900px;
      }
      .MainComDiv {
        position: relative;
        .SearchCom {
          text-align: center;
          background: nth($color, 1);
          box-shadow: 0 0px 6px rgba(147, 39, 143, 0.2);
          @include BorRadius(10px);
          margin-bottom: 30px;
          padding: 25px;
          h3 {
            @include Font(27, 600, 3);
            margin-bottom: 15px;
            span {
              color: nth($color, 4);
            }
          }
          .SearchDiv {
            form {
              input {
                width: 100%;
                background: transparent;
                padding: 8px 15px;
                border: 1px solid #c9c9c9;
                @include Font(16, 400, 3);
                @include BorRadius(15px);
                appearance: inherit;
                cursor: pointer;
                background-repeat: no-repeat, repeat;
                background-position: right 1.5em top 50%, 0 0;
                background-size: -0.25em auto, 100%;
              }
              select {
                width: 100%;
                background: transparent;
                padding: 8px 15px;
                border: 1px solid #c9c9c9;
                @include Font(16, 400, 3);
                @include BorRadius(15px);
                appearance: inherit;
                cursor: pointer;
                background-repeat: no-repeat, repeat;
                background-position: right 1.5em top 50%, 0 0;
                background-size: -0.25em auto, 100%;
              }
              .SearchBtn {
                width: 100%;
                background: transparent;
                padding: 8px 15px;
                background: nth($color, 5);
                border: 0;
                @include Font(20, 400, 1);
                @include BorRadius(25px);
                @include Tran(0.4s, ease-in-out);
                &:hover {
                  background: #af3a6c;
                }
              }
            }
          }
        }
        .ListImg {
          img {
            width: auto;
            height: 200px;
          }
        }
        .ListItemsDiv {
          position: relative;
          margin-bottom: 30px;
          .ListItemUl {
            position: relative;
            li {
              margin-bottom: 10px;
              padding: 0 10px;
              line-height: 18px;
              cursor: pointer;
              border-right: 1px solid nth($color, 3);
              @include Font(16, 400, 3);
              @include Media(1600) {
                font-size: 14px;
              }
              &:last-child {
                border: 0;
              }
              img {
                width: 20px;
                margin-right: 5px;
              }
            }
          }
        }
        .ItemsOuterDiv {
          position: relative;
          .ItemText {
            position: relative;
            .BreadCrumb {
              justify-content: flex-start;
              li {
                position: relative;
                @include Font(16, 600, 3);
                margin: 0 5px 10px;
                padding-right: 10px;
                &::before {
                  content: '>';
                  position: absolute;
                  top: 1px;
                  right: -10px;
                  width: 15px;
                  height: 15px;
                }
              }
            }
            p {
              @include FitContent();
              @include Font(18, 300, 3);
              margin-bottom: 20px;
              border-bottom: 1px solid nth($color, 5);
            }
            .Tabs {
              position: relative;
              .FilterTab {
                position: relative;
                li {
                  @include Font(16, 300, 3);
                  background: transparent;
                  padding: 8px 25px;
                  @include Tran(0.4s, ease-in-out);
                  @include BorRadius(25px);
                  cursor: pointer;
                  &.active,
                  &:hover {
                    background: #f3f3f3;
                    color: nth($color, 5);
                  }
                }
              }
              .GridTab {
                position: relative;
                li {
                  font-size: 20px;
                  margin: 0 5px;
                  color: nth($color, 3);
                  @include Tran(0.4s, ease-in-out);
                  cursor: pointer;
                  &.active,
                  &:hover {
                    color: nth($color, 5);
                  }
                  &:focus {
                    outline: none;
                  }
                }
              }
            }
          }
          .ItemDesc {
            position: relative;
            margin-bottom: 50px;
            .ItemsDiv {
              h4 {
                @include Font(24, 400, 4);
                margin: 30px 0;
              }
            }
          }
        }
      }
      .GoBack {
        position: relative;
        padding-left: 25px;
        display: block;
        @include Font(16, 600, 3);
        margin-bottom: 15px;
        &:before {
          content: '<<';
          position: absolute;
          top: 0;
          left: 0;
          width: 30px;
        }
      }
      .DetailsComponent {
        position: relative;
        background: nth($color, 1);
        box-shadow: 0 0px 6px rgba(147, 39, 143, 0.2);
        @include BorRadius(10px);
        margin-bottom: 30px;
        padding: 25px 50px;
        .Title {
          position: relative;
          h3 {
            @include Font(40, 400, 4);
            @include Media(1366) {
              font-size: 32px;
            }
          }
          strong {
            @include Font(40, 600, 5);
            @include Media(1366) {
              font-size: 30px;
            }
          }
        }
        .BreadCrumbUl {
          justify-content: flex-start;
          margin-bottom: 30px;
          li {
            position: relative;
            @include Font(16, 600, 3);
            margin: 0 5px 10px;
            padding-right: 10px;
            @include Media(1366) {
              font-size: 12px;
            }
            &::before {
              content: '>';
              position: absolute;
              top: 1px;
              right: -10px;
              width: 15px;
              height: 15px;
            }
          }
        }
        .carousel {
          position: relative;
          .carousel-inner {
            width: 80%;
            margin-left: auto;
            .carousel-item {
              position: relative;
              text-align: center;
              img {
                margin: 0 auto;
                display: block;
                width: auto;
                height: 400px;
              }
            }
          }
          .carousel-indicators {
            width: 19%;
            padding: 5px 15px 0 0;
            left: 0;
            right: auto;
            margin: 0;
            top: 0;
            height: 100%;
            overflow-y: scroll;
            flex-direction: column;
            li {
              border: 0;
              width: 100%;
              height: 100px;
              text-align: center;
              padding: 5px 0;
              img {
                width: 100%;
                height: auto;
                margin: 0 auto;
                display: block;
              }
            }
          }
        }
        .ChatUl {
          position: relative;
          li {
            width: 100%;
            @include BorRadius(25px);
            border: 1px solid nth($color, 3);
            margin-bottom: 15px;
            cursor: pointer;
            img {
              width: 40px;
              height: 40px;
              @include BorRadius(50%);
              box-shadow: 0 0px 6px rgba(147, 39, 143, 0.2);
            }
            span {
              padding-left: 15px;
              @include Font(16, 400, 3);
            }
          }
        }
        .PostedDate {
          position: relative;
          margin: 15px 0 30px;
          @include Font(16, 400, 3);
        }
        .Description {
          position: relative;
          margin-bottom: 30px;
          h5 {
            @include Font(18, 400, 5);
            margin-bottom: 30px;
          }
          .ExpandText {
            position: relative;
            p {
              @include Font(16, 400, 3);
            }
            a {
              display: block;
              padding: 15px 0;
              @include Font(15, 600, 5);
              border-bottom: 1px solid #bfbebe;
            }
          }
        }
        .DetailsListDiv {
          .DetailsListItem {
            position: relative;
            h6 {
              margin-bottom: 15px;
              @include Font(20, 400, 5);
            }
            .ListDetailsUl {
              position: relative;
              margin-bottom: 30px;
              li {
                span {
                  width: 50%;
                  display: inline-block;
                  @include Font(15, 400, 3);
                  img {
                    width: 15px;
                    margin-right: 10px;
                  }
                }
                b {
                  width: 50%;
                  display: inline-block;
                  @include Font(15, 600, 3);
                }
              }
            }
          }
        }
        .LocationDiv {
          position: relative;
          h6 {
            margin-bottom: 15px;
            @include Font(19, 400, 5);
          }
          h5 {
            margin-bottom: 15px;
            @include Font(18, 600, 4);
          }
          .LocationIframe {
            position: relative;
            iframe {
              width: 100%;
              height: 400px;
              border: 0;
              @include BorRadius(10px);
              box-shadow: 0 0px 6px rgba(147, 39, 143, 0.2);
            }
          }
        }
        .RelatedLocDiv {
          box-shadow: 0 0px 6px rgba(147, 39, 143, 0.2);
          @include BorRadius(25px);
          p {
            background: #efefef;
            text-align: center;
            padding: 15px 0;
            @include BorRadius(25px);
            @include Font(19, 400, 4);
          }
          .LocUl {
            display: block;
            position: relative;
            width: 100%;
            background: nth($color, 1);
            margin: 15px 0;
            li {
              margin: 5px 0px 5px 30px;
              @include Font(15, 400, 3);
            }
          }
        }
        .EmailerModal {
          .modal-dialog {
            max-width: 700px;
            box-shadow: 0 0px 6px rgba(147, 39, 143, 0.2);
            h5 {
              @include Font(22, 600, 4);
            }
          }
          .modal-header {
            border: 0;
            padding: 15px;
          }
          .form {
            label {
              display: block;
              text-align: right;
              padding: 10px 0;
              @include Font(18, 300, 3);
            }
            input {
              width: 100%;
              padding: 10px;
              border: 1px solid #e6e6e6;
              @include Font(16, 400, 3);
              @include BorRadius(25px);
            }
            textarea {
              @extend input;
              resize: none;
              height: 150px;
            }
            .EmailBtn {
              padding: 10px 50px;
              border: 0;
              background: nth($color, 5);
              @include BorRadius(25px);
              @include Font(18, 400, 1);
            }
          }
        }
      }
    }
    .RightSideBar {
      width: 280px;
      @include Media(1600) {
        width: 250px;
      }
      .RightSideSearch {
        position: relative;
        background: nth($color, 1);
        padding: 25px;
        box-shadow: 0 2px 11px rgba(147, 39, 143, 0.2);
        @include BorRadius(10px);
        &.BorderRad {
          @include BorRadius(25px);
        }
        margin-bottom: 30px;
        form {
          input[type='text'] {
            width: 100%;
            background: #f3f3f3;
            padding: 8px 15px;
            margin-bottom: 15px;
            border: 0;
            @include Font(14, 400, 3);
            @include BorRadius(25px);
            &.CustomInput {
              width: 90%;
              display: block;
              margin: 15px auto;
              background: transparent;
              border: 1px solid nth($color, 3);
              @include BorRadius(5px);
            }
          }
          .MuiSlider-root {
            color: nth($color, 4);
          }
          p.MuiTypography-root {
            @include Font(14, 400, 3);
            span {
              color: nth($color, 5);
            }
          }
          select {
            position: relative;
            width: 100%;
            background: #f3f3f3;
            padding: 8px 15px;
            border: 0;
            appearance: inherit;
            @include Font(14, 400, 3);
            @include BorRadius(25px);
            cursor: pointer;
            background-repeat: no-repeat, repeat;
            background-position: right 1.5em top 50%, 0 0;
            background-size: -0.25em auto, 100%;
          }
          .MuiFormControlLabel-root {
            display: block;
            margin: 0;
          }
          .SizesofCar {
            li {
              text-align: center;
              margin: 10px 0;
              img {
                width: auto;
                height: 30px;
                margin-bottom: 5px;
              }
              p {
                @include Font(14, 400, 3);
              }
            }
          }
        }
      }
      .RelatedSearch {
        h4 {
          margin-bottom: 30px;
          @include Font(20, 400, 4);
        }
      }
    }
    .SocialLinksDiv {
      position: relative;
      background: nth($color, 1);
      padding: 25px;
      box-shadow: 0 2px 11px rgba(147, 39, 143, 0.2);
      @include BorRadius(10px);
      margin-bottom: 30px;
      @include Media(1600) {
        padding: 15px 10px;
      }
      p {
        @include Font(14, 400, 3);
        margin-bottom: 15px;
      }
      .SocialLinks {
        a {
          width: 30px;
          height: 30px;
          margin: 0 5px;
          @include BorRadius(50%);
          @include Media(1600) {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}

@include Webkit('carousel-indicators');
