/* =============================
      Variables SCSS
============================= */
$color: #ffffff, #000000, #5a5a5a, #26326c, #c85084;

@mixin Flex($var1, $var2) {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: $var1;
  align-items: $var2;
}

@mixin Padding($var1, $var2) {
  padding: $var1 + px $var2 + px;
}

@mixin BG($var) {
  background: url('/assets/images/#{$var}') no-repeat center/cover;
}

@mixin Media($var) {
  @media only screen and (max-width: $var + px) {
    @content;
  }
}

@mixin Absolute($var1, $var2, $var3, $var4, $var5, $var6, $var7) {
  content: $var1;
  position: absolute;
  top: $var2;
  bottom: $var3;
  left: $var4;
  right: $var5;
  width: $var6;
  height: $var7;
}

@mixin Tran($var1, $var2) {
  transition: all $var1 $var2;
  -webkit-transition: all $var1 $var2;
  -moz-transition: all $var1 $var2;
  -ms-transition: all $var1 $var2;
  -o-transition: all $var1 $var2;
}

@mixin TransForm($var) {
  transform: $var;
  -webkit-transform: $var;
  -moz-transform: $var;
  -ms-transform: $var;
  -o-transform: $var;
}

@mixin BorRadius($var) {
  border-radius: $var;
  -webkit-border-radius: $var;
  -moz-border-radius: $var;
  -ms-border-radius: $var;
  -o-border-radius: $var;
}

@mixin Img($var) {
  width: 100%;
  height: 100%;
  object-fit: $var;
}

@mixin Font($var1, $var2, $var3) {
  font-size: $var1 + px;
  font-weight: $var2;
  color: nth($color, $var3);
}

@mixin FitContent {
  width: fit-content;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: -ms-fit-content;
  width: -o-fit-content;
}

@mixin Webkit($var) {
  /* width */
  .#{$var}::-webkit-scrollbar {
    width: 8px;
    @include BorRadius(25px);
  }

  /* Track */
  .#{$var}::-webkit-scrollbar-track {
    background: #f1f1f1;
    @include BorRadius(25px);
  }

  /* Handle */
  .#{$var}::-webkit-scrollbar-thumb {
    background: #707070;
    @include BorRadius(25px);
  }

  /* Handle on hover */
  .#{$var}::-webkit-scrollbar-thumb:hover {
    background: #707070;
    @include BorRadius(25px);
  }
}

/* =============================
        HTML & BODY
============================= */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
  overflow-x: hidden;
  position: relative;
}

body {
  width: 100vw;
  min-height: 100vh;
  padding: 0 !important;
  margin: 0;
  overflow-x: hidden;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: nth($color, 2);
  position: relative;
}

.DBlock,
header,
section,
footer {
  float: left;
  width: 100%;
  height: 100%;
  display: block;
}

.DFlex {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  letter-spacing: normal;
  text-transform: none;
  line-height: normal;
  margin-bottom: 0;
}

label {
  margin-bottom: 0;
}

ul,
ol {
  list-style: none;
  margin-bottom: 0;
}

.FocusNone,
a:focus,
div:focus,
button:focus,
textarea:focus,
input:focus,
select:focus {
  outline: none !important;
}

.TransAni {
  transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}

img {
  max-width: 100%;
  height: auto;
}

a:hover {
  cursor: pointer;
  color: inherit;
  text-decoration: none !important;
}

.modal-open {
  padding-right: 0 !important;
}

.MobileWrapper,
.DesktopWrapper,
.ClaseedWrapper {
  min-height: 100vh;
  position: relative;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

// Scroll Bar
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f2f2f2;
  @include BorRadius(10px);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: nth($color, 5);
  @include BorRadius(10px);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: nth($color, 4);
}

.ListViewDiv {
  position: relative;
  .ListView {
    background: nth($color, 1);
    box-shadow: 0px 0px 4px rgba(147, 39, 143, 0.25);
    position: relative;
    @include BorRadius(10px);
    overflow: hidden;
    margin-bottom: 30px;
    .ListImg {
      width: 30%;
      height: 100%;
      background: #f2f2f2;
      padding: 15px;
      .carousel {
        background: nth($color, 1);
        height: 100%;
        img {
          width: auto !important;
          height: 250px;
          object-fit: cover;
          margin: 0 auto;
        }
      }
    }
    .ListText {
      width: 70%;
      padding: 15px 15px 15px 30px;
      @include Media(1600) {
        padding: 15px;
      }
      .TextHeader {
        margin-bottom: 20px;
        a {
          @include Font(18, 600, 4);
        }
        p {
          @include Font(16, 400, 3);
        }
        span {
          a {
            @include Font(16, 600, 4);
          }
          small {
            display: block;
            @include Font(14, 300, 4);
          }
        }
        strong {
          @include Font(16, 600, 5);
        }
      }
      .TextBody {
        margin-bottom: 10px;
        p {
          @include Font(16, 300, 3);
          @include Media(1600) {
            font-size: 14px;
          }
        }
        .CanListUl {
          position: relative;
          li {
            width: auto;
            margin: 0 10px 5px;
            display: inline-block;
            @include Font(14, 600, 4);
            &:first-child {
              margin-left: 0;
            }
            &:last-child {
              margin-right: 0;
            }
            span {
              margin-left: 10px;
              @include Font(14, 400, 4);
            }
          }
        }
        .TextBodyUl {
          position: relative;
          li {
            width: 50%;
            margin-bottom: 15px;
            display: inline-block;
            @include Font(14, 600, 4);
            span {
              margin-left: 10px;
              @include Font(14, 400, 4);
            }
          }
        }
      }
      .TextFooter {
        position: relative;
        .ListOl {
          width: auto;
          li {
            @include Font(16, 400, 4);
          }
        }
        .CanListOl {
          width: auto;
          li {
            @include Font(16, 600, 4);
            @include Media(1366) {
              font-size: 14px;
            }
            span {
              font-weight: 400;
              margin-left: 10px;
            }
          }
        }
        .ListIconUl {
          width: auto;
          a {
            @include Flex(center, center);
            width: 40px;
            height: 40px;
            margin: 5px;
            background: nth($color, 1);
            box-shadow: 2px 2px 4px rgba(147, 39, 143, 0.2);
            @include BorRadius(50%);
            @include Tran(0.4s, ease-in-out);
            &:hover {
              background: nth($color, 4);
              .icon {
                @include Tran(0.4s, ease-in-out);
                color: nth($color, 1);
              }
            }
            .icon {
              font-size: 18px;
              color: nth($color, 4);
            }
            .heart {
              font-size: 18px;
              color: nth($color, 5);
            }
            &.ViewJob {
              display: block;
              width: auto;
              height: auto;
              padding: 5px 30px;
              color: nth($color, 1);
              background: nth($color, 5);
              @include BorRadius(25px);
              @include Tran(0.4s, ease-in-out);
              &:hover {
                background: #861747;
              }
            }
          }
        }
      }
    }
  }
}

.GridViewDiv {
  position: relative;
  .ListView {
    background: nth($color, 1);
    box-shadow: 0px 0px 4px rgba(147, 39, 143, 0.2);
    position: relative;
    @include BorRadius(10px);
    align-items: flex-start;
    overflow: hidden;
    margin-bottom: 30px;
    .ListImg {
      width: 100%;
      background: #f2f2f2;
      padding: 15px;
      .carousel {
        background: nth($color, 1);
        img {
          width: auto !important;
          height: 200px;
          object-fit: cover;
          margin: 0 auto;
        }
      }
    }
    .ListText {
      width: 100%;
      padding: 15px 15px 15px 30px;
      @include Media(1600) {
        padding: 15px 10px;
      }
      .TextHeader {
        margin-bottom: 20px;
        a {
          @include Font(18, 600, 4);
          @include Media(1600) {
            font-size: 16px;
          }
        }
        p {
          @include Font(16, 400, 3);
          @include Media(1600) {
            font-size: 14px;
          }
        }
        span {
          a {
            @include Font(16, 400, 4);
            @include Media(1600) {
              font-size: 14px;
            }
          }
          small {
            display: block;
            @include Font(14, 300, 4);
            @include Media(1600) {
              font-size: 12px;
            }
          }
        }
        strong {
          @include Font(16, 600, 5);
          @include Media(1600) {
            font-size: 14px;
          }
        }
      }
      .TextBody {
        margin-bottom: 10px;
        p {
          @include Font(16, 300, 3);
          @include Media(1600) {
            font-size: 14px;
          }
        }
        .CanListUl {
          position: relative;
          li {
            width: 100%;
            margin-bottom: 5px;
            display: inline-block;
            @include Font(14, 600, 4);
            span {
              margin-left: 10px;
              @include Font(14, 400, 4);
            }
          }
        }
        .TextBodyUl {
          position: relative;
          li {
            width: 100%;
            margin-bottom: 5px;
            display: inline-block;
            @include Font(14, 600, 4);
            span {
              margin-left: 10px;
              @include Font(14, 400, 4);
            }
          }
        }
      }
      .TextFooter {
        position: relative;
        .ListOl {
          width: 100%;
          li {
            @include Font(16, 400, 4);
            @include Media(1600) {
              font-size: 14px;
            }
          }
        }
        .CanListOl {
          width: 100%;
          li {
            @include Font(14, 600, 4);
            span {
              font-weight: 400;
              margin-left: 10px;
            }
          }
        }
        .ListIconUl {
          margin-top: 15px;
          @include Flex(center, center);
          a {
            @include Flex(center, center);
            width: 40px;
            height: 40px;
            margin: 5px;
            background: nth($color, 1);
            box-shadow: 2px 2px 4px rgba(147, 39, 143, 0.2);
            @include BorRadius(50%);
            @include Tran(0.4s, ease-in-out);
            &.ViewJob {
              display: block;
              width: auto;
              height: auto;
              padding: 5px 30px;
              color: nth($color, 1);
              background: nth($color, 5);
              @include BorRadius(25px);
              @include Tran(0.4s, ease-in-out);
              &:hover {
                background: #861747;
              }
            }
            &:hover {
              background: nth($color, 4);
              .icon {
                @include Tran(0.4s, ease-in-out);
                color: nth($color, 1);
              }
              .heart {
                color: nth($color, 1);
              }
            }
            .icon {
              font-size: 18px;
              color: nth($color, 4);
            }
            .heart {
              font-size: 18px;
              color: nth($color, 5);
            }
          }
        }
      }
    }
  }
}

.canvasjs-chart-credit {
  display: none;
}

.TopBar {
  position: relative;
  padding: 10px;
  background: #f2f2f2;
  .icon {
    font-size: 16px;
    color: nth($color, 3);
  }
  p {
    margin: 0 auto;
    @include Font(18, 600, 4);
  }
}
