@import '../../../index.scss';

.MobFooter {
  padding: 15px 10px;
  background: nth($color, 1);
  box-shadow: 0px -2px 4px rgba(147, 37, 143, 0.25);
  position: fixed;
  bottom: 0;
  left: 0;
  top: auto;
  height: auto !important;
  .FooterLinks {
    position: relative;
    a {
      @include Font(14, 400, 5);
      text-align: center;
      img {
        display: block;
        margin: 0 auto 2px;
        width: 20px;
      }
      span {
        color: nth($color, 4);
        text-align: center;
      }
    }
  }
}
