@import '../../../index.scss';

/* =============================
      Profile Section
============================= */
.ProfileWrapper {
  position: relative;
  padding: 120px 0;
  @include Media(575) {
    padding: 50px 15px 100px;
  }
  .LeftSideBar {
    width: 180px;
    padding: 10px 0;
    position: relative;
    @include Media(1366) {
      width: 150px;
    }
    .SideUl {
      height: 100%;
      overflow: hidden;
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
      background: nth($color, 1);
      box-shadow: 2px 2px 4px rgba(147, 39, 143, 0.25);
      a {
        display: block;
        position: relative;
        padding: 30px 15px 30px 30px;
        @include Font(18, 700, 4);
        @include Tran(0.4s, ease-in-out);
        @include Media(1366) {
          padding: 15px 5px 15px 15px;
        }
        .icon {
          margin-right: 10px;
        }
        &.active,
        &:hover {
          background: rgba(147, 39, 143, 0.25);
          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 5px;
            height: 100%;
            background: nth($color, 5);
          }
        }
      }
    }
  }
  .SelectCategory {
    position: relative;
    margin-bottom: 50px;
    @include Media(575) {
      flex-direction: column;
    }
    label {
      @include Font(20, 700, 3);
      margin-right: 10px;
      @include Media(575) {
        margin: 0 0 15px 0;
      }
    }
    select {
      padding: 5px 20px;
      @include Font(16, 600, 5);
      border: 1px solid nth($color, 5);
      @include BorRadius(25px);
      @include Media(575) {
        width: 100%;
      }
    }
  }
  .MainProfileDiv {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    float: none;
    position: relative;
    @include Media(1366) {
      max-width: 800px;
    }
    .ProfileDiv {
      position: relative;
      box-shadow: 0px 0px 6px rgba(147, 39, 143, 0.3);
      @include BorRadius(25px);
      background: nth($color, 1);
      .ProfileData {
        position: relative;
        box-shadow: 0px 0px 6px rgba(147, 39, 143, 0.3);
        @include BorRadius(25px);
        background: nth($color, 1);
        padding: 15px;
        margin-bottom: 15px;
        @include Media(575) {
          padding: 15px !important;
        }
        .ProfileImg {
          position: relative;
          @include Media(575) {
            text-align: center;
          }
          img {
            @include Img('cover');
            width: 250px;
            height: 250px;
            @include BorRadius(50%);
            @include Media(575) {
              width: 200px;
              height: 200px;
            }
          }
          a {
            @include Flex(center, center);
            @include BorRadius(50%);
            position: absolute;
            left: 200px;
            bottom: 20px;
            width: 30px;
            height: 30px;
            background: nth($color, 1);
            box-shadow: 0px 0px 6px rgba(147, 39, 143, 0.3);
            @include Font(16, 400, 3);
            @include Media(575) {
              left: 70%;
              bottom: 15px;
            }
          }
        }
        .ProfileText {
          position: relative;
          h2 {
            @include Font(20, 700, 3);
            margin-bottom: 5px;
          }
          p {
            @include Font(18, 400, 3);
            margin-bottom: 5px;
          }
          a {
            @include Flex(center, center);
            @include BorRadius(50%);
            position: absolute;
            right: 20px;
            top: 10px;
            width: 30px;
            height: 30px;
            background: nth($color, 1);
            box-shadow: 0px 0px 6px rgba(147, 39, 143, 0.3);
            @include Font(16, 400, 3);
          }
        }
      }
      .ProfileForm {
        position: relative;
        form {
          label {
            @include Font(14, 400, 3);
          }
          input {
            width: 100%;
            background: #f3f3f3;
            padding: 8px 15px;
            margin-bottom: 0;
            border: 0;
            @include Font(14, 400, 3);
            @include BorRadius(25px);
          }
          button[type='submit'] {
            padding: 5px 20px;
            background: nth($color, 5);
            border: 0;
            display: block;
            margin: 0 auto;
            @include Font(16, 400, 1);
            @include BorRadius(25px);
          }
          span {
            @include Font(12, 400, 3);
          }
        }
        .ChangePasswordBtn {
          background: transparent;
          border: 0;
          @include Font(16, 400, 5);
          margin: 20px 0;
        }
      }
      .SavedProfileDiv {
        position: relative;
        padding-bottom: 20px;
        .MuiAccordion-root {
          box-shadow: none;
          .MuiAccordionSummary-root {
            @include FitContent();
            background: nth($color, 5);
            @include BorRadius(25px);
            justify-content: flex-start;
            min-height: 40px;
            @include Font(20, 400, 1);
            margin: 0 15px 15px;
            &.Mui-expanded {
              min-height: 40px;
            }
            .MuiAccordionSummary-content {
              flex-grow: 0;
              margin: 0;
            }
            .MuiIconButton-root {
              padding: 0 15px;
              svg {
                fill: nth($color, 1);
              }
            }
          }
        }
        .SavedAccBodyDiv {
          border-top: 1px solid nth($color, 5);
          padding: 30px 10px;
          .Title {
            position: relative;
            h4 {
              @include Font(20, 600, 5);
            }
          }
          .DescUl {
            position: relative;
            li {
              padding: 10px 0;
              border-bottom: 1px solid nth($color, 3);
            }
            span {
              @include Font(16, 400, 3);
            }
            button {
              background: transparent;
              border: 0;
              @include Font(16, 400, 3);
              margin: 0 5px;
            }
          }
        }
      }
    }
    .PromoteDiv {
      position: relative;
      margin: 30px 15px;
      .PlanDiv {
        position: relative;
        box-shadow: 0px 0px 4px rgba(147, 39, 143, 0.25);
        padding: 15px;
        @include BorRadius(5px);
        h3 {
          text-align: center;
          margin-bottom: 30px;
          @include Font(22, 600, 4);
        }
        .PlanUl {
          position: relative;
          height: auto;
          list-style: inside;
          margin-bottom: 15px;
          li {
            @include Font(16, 400, 4);
            margin-bottom: 5px;
          }
        }
        .PlanBtn {
          margin: 0 auto;
          display: block;
          padding: 5px 25px;
          border: 1px solid nth($color, 5);
          background: transparent;
          @include Font(18, 600, 4);
          @include BorRadius(25px);
          @include Tran(0.4s, ease-in-out);
          &:hover {
            color: nth($color, 1);
            background: nth($color, 5);
          }
        }
      }
      .PlanForm {
        position: relative;
        box-shadow: 0px 0px 4px rgba(147, 39, 143, 0.25);
        padding: 15px;
        @include BorRadius(5px);
        h3 {
          @include Font(22, 600, 4);
          margin-bottom: 30px;
        }
        .PlanFormPayment {
          justify-content: center;
          margin-bottom: 30px;
          li {
            width: 50px;
            margin: 0 10px;
            @include BorRadius(5px);
            &.active {
              border: 1px solid nth($color, 5);
            }
          }
        }
        .SuccessFullPlanMsg {
          position: relative;
          padding: 30px;
          height: auto;
          box-shadow: 0px 0px 4px rgba(147, 39, 143, 0.25);
          @include BorRadius(10px);
          img {
            width: 60px;
            margin-bottom: 10px;
          }
          h6 {
            @include Font(18, 600, 3);
            margin-bottom: 5px;
          }
          p {
            @include Font(16, 400, 3);
          }
        }
        form {
          position: relative;
          label {
            @include Font(16, 400, 3);
            margin-bottom: 10px;
          }
          input {
            width: 100%;
            border: 1px solid #dbdbdb;
            @include BorRadius(10px);
            background: transparent;
            @include Font(16, 400, 3);
            padding: 5px 15px;
          }
          .PlanSubBtn {
            display: block;
            margin: 0 auto;
            border: 0;
            background: nth($color, 4);
            padding: 8px 25px;
            @include BorRadius(25px);
            @include Font(16, 600, 1);
            &:hover {
              background: #131c48;
            }
          }
        }
      }
    }
  }
  .MyAdsListDiv {
    position: relative;
    margin-bottom: 30px;
    box-shadow: 0px 0px 4px rgba(147, 39, 143, 0.25);
    @include BorRadius(10px);
    .AccOuterDiv {
      padding: 0 0 15px;
      .MuiAccordion-root {
        box-shadow: none;
      }
      .AccTitle {
        padding: 0 10px;
      }
      .MuiAccordionSummary-root {
        background: nth($color, 5);
        @include FitContent();
        @include BorRadius(25px);
        @include Font(20, 400, 1);
        min-height: 40px;
        .MuiAccordionSummary-content {
          margin: 0;
          min-height: 40px;
        }
        .MuiAccordionSummary-expandIcon {
          padding: 0 10px;
          .MuiSvgIcon-root {
            fill: nth($color, 1);
          }
        }
      }
      .MuiAccordionDetails-root {
        padding: 25px 0px;
        .AccBodyDiv {
          position: relative;
          border-top: 1px solid #c8508480;
          border-bottom: 1px solid #c8508480;
          padding: 0 10px;
          .Title {
            position: relative;
            margin-bottom: 30px;
            .TextDiv {
              position: relative;
              width: auto;
              h3 {
                @include Font(20, 600, 5);
              }
              p {
                @include Font(12, 400, 5);
              }
            }
            .BtnsDiv {
              width: auto;
              position: relative;
              button {
                padding: 5px 25px;
                margin: 0 5px;
                border: 0;
                background: transparent;
                @include BorRadius(25px);
                @include Tran(0.4s, ease-in-out);
                &.active,
                &:hover {
                  background: #f1f1f1;
                  color: nth($color, 5);
                }
              }
            }
          }
          .Description {
            padding: 0 5px;
          }
          thead {
            th {
              padding: 10px 5px;
              @include Font(16, 700, 5);
            }
          }
          tbody {
            td {
              padding: 5px;
              @include Font(16, 400, 3);
              button {
                background: transparent;
                border: 0;
                @include Font(16, 400, 3);
              }
            }
          }
        }
      }
      .Views {
        @include Font(18, 600, 4);
        .icon {
          color: nth($color, 5);
        }
      }
      .AccBtnDiv {
        position: relative;
        @include Media(575) {
          justify-content: space-between !important;
          width: 100% !important;
          margin: 15px 0;
        }
        a {
          padding: 5px 20px;
          background: nth($color, 4);
          @include BorRadius(25px);
          @include Font(18, 400, 1);
          @include Tran(0.4s, ease-in-out);
          margin: 0 5px;
          &:hover {
            background: #131c48;
          }
        }
        button {
          border: 0;
          background: transparent;
          @include Font(18, 400, 3);
          margin: 0 10px;
        }
      }
    }
  }
}
