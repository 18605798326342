@import '../../../index.scss';

.SignFormWrapper {
  position: relative;
  padding: 70px 0 180px;
  @include Media(767) {
    padding: 50px 0 100px;
  }
  .SignFormOuterDiv {
    position: relative;
    width: 650px;
    margin: 0 auto;
    float: none;
    box-shadow: 0px 0px 8px rgba(147, 39, 143, 0.4);
    padding: 25px 60px;
    text-align: center;
    @include BorRadius(10px);
    @include Media(767) {
      width: 90%;
      margin: 0 auto;
      padding: 25px 15px;
    }
    .SignInfo {
      height: auto;
      float: none;
      margin-bottom: 35px;
      h3 {
        @include Font(20, 600, 4);
        margin-bottom: 20px;
      }
      .InfoBtn {
        position: relative;
        button {
          position: relative;
          padding: 8px 60px;
          margin: 0 10px;
          border: 1px solid nth($color, 5);
          background: transparent;
          @include BorRadius(25px);
          @include Media(767) {
            margin: 0 auto 10px;
          }
          &::before {
            content: '';
            position: absolute;
            top: 2px;
            left: 3px;
            width: 36px;
            height: 36px;
            @include BorRadius(50%);
            @include BG('Facebook.png');
          }
          &.Google::before {
            @include BG('LoginGoggle.png');
          }
        }
      }
    }
    .SignFormDiv {
      width: 300px;
      margin: 0 auto;
      height: auto;
      float: none;
      h3 {
        @include Font(20, 600, 4);
        margin-bottom: 20px;
      }
      p {
        @include Font(16, 400, 3);
        margin-bottom: 20px;
      }
      form {
        input {
          width: 100%;
          position: relative;
          padding: 8px 30px;
          border: 1px solid nth($color, 3);
          @include Font(16, 400, 3);
          @include BorRadius(25px);
        }
        .ShowPassBtn {
          background: transparent;
          border: 0;
          position: absolute;
          top: 10px;
          right: 15px;
          .icon {
            font-size: 22px;
            color: nth($color, 5);
          }
        }
        .PrivateRadioButtonIcon-root-5 {
          svg {
            fill: nth($color, 3);
          }
        }
        .SignBtn {
          position: relative;
          padding: 8px 60px;
          margin: 0 10px;
          border: 0;
          background: nth($color, 5);
          @include BorRadius(25px);
          @include Tran(0.4s, ease-in-out);
          @include Font(18, 400, 1);
          &:hover {
            background: #8e0f46;
          }
        }
      }
    }
    a {
      display: block;
      margin-bottom: 15px;
      @include Font(16, 400, 3);
    }
    p {
      @include Font(16, 400, 3);
      a {
        display: inline-block;
        margin-bottom: 0px;
        @include Font(16, 600, 4);
      }
    }
  }
}
