@import '../../../index.scss';

.HomeArea {
  position: relative;
  .HomeDiv {
    position: relative;
    margin: 60px 0 300px;
    @include Media(767) {
      margin: 30px 0 100px;
    }
    .Title {
      position: relative;
      margin-bottom: 100px;
      @include Media(767) {
        margin-bottom: 50px;
      }
      h2 {
        @include Font(50, 700, 4);
        text-align: center;
        margin-bottom: 50px;
        span {
          color: nth($color, 5);
        }
        @include Media(1366) {
          font-size: 40px;
          margin-bottom: 20px;
        }
        @include Media(767) {
          font-size: 26px;
          margin-bottom: 15px;
        }
      }
      form {
        position: relative;
        .form-group {
          position: relative;
          max-width: 70%;
          margin: 0 auto;
          @include Flex(flex-start, center);
          border: 1px solid nth($color, 3);
          @include BorRadius(25px);
          @include Media(1366) {
            max-width: 60%;
          }
          @include Media(991) {
            max-width: 100%;
          }
          @include Media(767) {
            max-width: 100%;
            justify-content: space-between;
          }
          input {
            width: calc(100% - 50px);
            border: 0;
            @include Font(18, 400, 3);
            padding: 10px 25px;
            background: transparent;
            @include Media(767) {
              padding: 8px 15px;
              font-size: 14px;
            }
          }
          .SubBtn {
            @include Flex(center, center);
            display: block;
            width: 50px;
            height: 50px;
            background: nth($color, 5);
            border: 0;
            @include BorRadius(50%);
            .icon {
              font-size: 24px;
              color: nth($color, 1);
              @include Media(767) {
                font-size: 18px;
              }
            }
            @include Media(767) {
              width: 40px;
              height: 40px;
            }
          }
        }
      }
    }
    .DescUl {
      position: relative;
      @include Media(1366) {
        justify-content: center;
      }
      @include Media(991) {
        flex-direction: column;
      }
      .DropDown {
        @include Media(767) {
          margin: 0 auto;
        }
        &:hover {
          .DropDownMenu {
            display: block;
          }
        }
        a {
          @include Flex(center, center);
          flex-direction: column;
          margin-bottom: 15px;
          width: 175px;
          background: nth($color, 1);
          padding: 40px 0;
          box-shadow: 0px 0px 10px rgba(147, 39, 143, 0.25);
          @include BorRadius(10px);
          @include Media(1366) {
            width: 150px;
            padding: 30px 0;
            margin: 0 15px 15px;
          }
          img {
            width: auto;
            height: 40px;
            margin-bottom: 20px;
            @include Media(767) {
              height: 35px;
              margin-bottom: 15px;
            }
          }
          p {
            @include Font(22, 500, 4);
            @include Media(1366) {
              font-size: 18px;
            }
          }
        }
        .DropDownMenu {
          position: absolute;
          display: none;
          top: 100%;
          left: 0;
          width: 100%;
          height: auto;
          padding: 15px;
          background: nth($color, 1);
          box-shadow: 2px 2px 6px rgba(147, 37, 143, 0.2);
          @include BorRadius(10px);
          @include Media(1366) {
            padding: 15px 5px;
            top: 110%;
          }
          .DropDownUl {
            position: relative;
            height: 100%;
            z-index: 9;
            li {
              margin-bottom: 10px;
              padding: 0 15px;
              @include Font(16, 400, 3);
              line-height: 16px;
              border-right: 1px solid nth($color, 3);
              @include Media(1366) {
                font-size: 14px;
              }
              &:hover {
                color: nth($color, 5);
                cursor: pointer;
              }
              &:last-child {
                border: 0;
              }
            }
          }
        }
      }
    }
  }
}
