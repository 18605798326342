@import '../../../index.scss';

.JobsCom {
  position: relative;
  padding: 30px 30px 100px 0;
  .MainComponent {
    position: relative;
    background: nth($color, 1);
    box-shadow: 0 0px 6px rgba(147, 39, 143, 0.2);
    @include BorRadius(10px);
    padding: 25px;
    .JobCheckDiv {
      position: relative;
      background: nth($color, 1);
      box-shadow: 0 0px 6px rgba(147, 39, 143, 0.25);
      @include BorRadius(10px);
      padding: 25px 25px 35px;
      h3 {
        @include Font(27, 600, 3);
        margin-bottom: 30px;
        span {
          color: nth($color, 4);
        }
      }
      .JobSerchUl {
        margin: 0 auto;
        a {
          padding: 8px 30px;
          margin: 0 15px;
          background: nth($color, 5);
          @include Font(20, 300, 1);
          box-shadow: 2px 2px 4px rgba(147, 39, 143, 0.2);
          @include BorRadius(25px);
          @include Tran(0.4s, ease-in-out);
          &:hover {
            background: #881f4c;
          }
          img {
            width: 15px;
            margin-right: 5px;
          }
        }
      }
    }
  }
  .CompDiv {
    position: relative;
    align-items: flex-start;
    .LeftSideBar {
      width: 100px;
      background: nth($color, 1);
      box-shadow: 0 0px 6px rgba(147, 39, 143, 0.2);
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      overflow: hidden;
      margin-top: 170px;
      .LeftSidebar {
        position: relative;
        a {
          display: block;
          text-align: center;
          padding: 30px;
          &.active {
            background: rgba(147, 39, 143, 0.2);
          }
          &:hover {
            background: rgba(147, 39, 143, 0.2);
          }
        }
      }
    }
    .MainComponent {
      width: 1140px;
      @include Media(1600) {
        width: 900px;
      }
      .MainComDiv {
        position: relative;
        .SearchCom {
          text-align: center;
          background: nth($color, 1);
          box-shadow: 0 0px 6px rgba(147, 39, 143, 0.2);
          @include BorRadius(10px);
          margin-bottom: 30px;
          padding: 25px;
          h3 {
            @include Font(27, 600, 3);
            margin-bottom: 15px;
            span {
              color: nth($color, 5);
            }
          }
          .SearchDiv {
            form {
              input {
                width: 100%;
                background: transparent;
                padding: 8px 15px;
                border: 1px solid #c9c9c9;
                @include Font(16, 400, 3);
                @include BorRadius(15px);
                appearance: inherit;
                cursor: pointer;
                background-repeat: no-repeat, repeat;
                background-position: right 1.5em top 50%, 0 0;
                background-size: -0.25em auto, 100%;
              }
              select {
                width: 100%;
                background: transparent;
                padding: 8px 15px;
                border: 1px solid #c9c9c9;
                @include Font(16, 400, 3);
                @include BorRadius(15px);
                appearance: inherit;
                cursor: pointer;
                background-repeat: no-repeat, repeat;
                background-position: right 1.5em top 50%, 0 0;
                background-size: -0.25em auto, 100%;
              }
              .SearchBtn {
                width: 100%;
                background: transparent;
                padding: 8px 15px;
                background: nth($color, 5);
                border: 0;
                @include Font(20, 400, 1);
                @include BorRadius(25px);
                @include Tran(0.4s, ease-in-out);
                &:hover {
                  background: #af3a6c;
                }
              }
            }
          }
        }
        .ResumeDiv {
          background: #f2f2f2;
          padding: 25px 45px;
          margin-bottom: 30px;
          @include BorRadius(10px);
          p {
            @include Font(20, 600, 4);
          }
          a {
            padding: 8px 40px;
            background: nth($color, 5);
            @include Font(16, 400, 1);
            @include BorRadius(25px);
            @include Tran(0.4s, ease-in-out);
            &:hover {
              background: #af3a6c;
            }
          }
        }
        .ListItemsDiv {
          position: relative;
          margin-bottom: 30px;
          .ListItemUl {
            position: relative;
            li {
              margin-bottom: 10px;
              padding: 0 10px;
              line-height: 18px;
              cursor: pointer;
              border-right: 1px solid nth($color, 3);
              @include Font(16, 400, 3);
              @include Media(1366) {
                font-size: 14px;
              }
              &:last-child {
                border: 0;
              }
              img {
                width: 20px;
                margin-right: 5px;
              }
            }
          }
        }
        .ItemsOuterDiv {
          position: relative;
          .ItemText {
            position: relative;
            .BreadCrumb {
              justify-content: flex-start;
              li {
                position: relative;
                @include Font(16, 600, 3);
                margin: 0 5px 10px;
                padding-right: 10px;
                &::before {
                  content: '>';
                  position: absolute;
                  top: 1px;
                  right: -10px;
                  width: 15px;
                  height: 15px;
                }
              }
            }
            p {
              @include FitContent();
              @include Font(18, 300, 3);
              margin-bottom: 20px;
              border-bottom: 1px solid nth($color, 5);
            }
            .Tabs {
              position: relative;
              .FilterTab {
                position: relative;
                li {
                  @include Font(16, 300, 3);
                  background: transparent;
                  padding: 8px 25px;
                  @include Tran(0.4s, ease-in-out);
                  @include BorRadius(25px);
                  cursor: pointer;
                  &.active,
                  &:hover {
                    background: #f3f3f3;
                    color: nth($color, 5);
                  }
                }
              }
              .GridTab {
                position: relative;
                li {
                  font-size: 20px;
                  margin: 0 5px;
                  color: nth($color, 3);
                  @include Tran(0.4s, ease-in-out);
                  cursor: pointer;
                  &.active,
                  &:hover {
                    color: nth($color, 5);
                  }
                  &:focus {
                    outline: none;
                  }
                }
              }
            }
          }
          .ItemDesc {
            position: relative;
            margin-bottom: 50px;
            .ItemsDiv {
              h4 {
                @include Font(24, 400, 4);
                margin: 30px 0;
              }
            }
          }
        }
      }
      .GoBack {
        position: relative;
        padding-left: 25px;
        display: block;
        @include Font(16, 600, 3);
        margin-bottom: 15px;
        &:before {
          content: '<<';
          position: absolute;
          top: 0;
          left: 0;
          width: 30px;
        }
      }
      .DetailsComponent {
        position: relative;
        background: nth($color, 1);
        box-shadow: 0 0px 6px rgba(147, 39, 143, 0.2);
        @include BorRadius(10px);
        margin-bottom: 30px;
        padding: 25px 50px;
        .JobDetailsInnerDiv {
          position: relative;
          padding: 50px;
          background: nth($color, 1);
          box-shadow: 0 0px 6px rgba(147, 39, 143, 0.2);
          @include BorRadius(10px);
          .JobDetImg {
            width: 150px;
            height: 150px;
            position: absolute;
            right: 60px;
            top: -35px;
            background: nth($color, 1);
            padding: 10px;
            box-shadow: 0 0px 6px rgba(147, 39, 143, 0.2);
            @include BorRadius(10px);
            img {
              @include Img(cover);
            }
          }
          h3 {
            @include Font(24, 600, 4);
            margin-bottom: 10px;
          }
          .JobUl {
            position: relative;
            margin-bottom: 30px;
            li {
              @include Font(16, 600, 3);
              span {
                @include Font(16, 400, 3);
                margin-left: 10px;
              }
            }
          }
          .JobDescription {
            position: relative;
            margin-bottom: 50px;
            p {
              @include Font(16, 400, 3);
              margin-bottom: 10px;
            }
          }
          .JobPagination {
            position: relative;
            z-index: 9;
            background: #f2f2f2;
            &::before,
            &::after {
              content: '';
              position: absolute;
              top: 0;
              left: -50px;
              width: 50px;
              height: 100%;
              z-index: -1;
              background: #f2f2f2;
            }
            &::after {
              left: auto;
              right: -50px;
            }
            .Prev {
              cursor: pointer;
              @include Font(16, 400, 3);
              span {
                @include Flex(center, center);
                width: 30px;
                height: 30px;
                background: nth($color, 4);
                @include BorRadius(50%);
                margin-right: 5px;
                .icon {
                  font-size: 16px;
                  color: nth($color, 1);
                }
              }
            }
            .Apply {
              position: relative;
              button {
                padding: 5px 30px;
                border: 0;
                background: nth($color, 5);
                @include Font(18, 400, 1);
                @include BorRadius(25px);
                @include Tran(0.4s, ease-in-out);
                &:hover {
                  background: #8e1c4d;
                }
              }
            }
            .Next {
              cursor: pointer;
              @include Font(16, 400, 3);
              span {
                @include Flex(center, center);
                width: 30px;
                height: 30px;
                background: nth($color, 4);
                @include BorRadius(50%);
                margin-left: 5px;
                .icon {
                  font-size: 16px;
                  color: nth($color, 1);
                }
              }
            }
          }
        }
      }
      .JobModal {
        .modal-header {
          h5 {
            @include Font(24, 600, 4);
          }
        }
      }
      .JobModalBodyUl {
        margin-bottom: 20px;
        .UploadCv {
          position: relative;
          background: nth($color, 5);
          padding: 8px 25px;
          @include Tran(0.4s, ease-in-out);
          @include BorRadius(25px);
          cursor: pointer;
          &:hover {
            color: nth($color, 1);
            background: nth($color, 4);
          }
          label {
            @include Font(16, 400, 1);
            cursor: pointer;
          }
          input {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 9;
            cursor: pointer;
          }
        }
        a {
          margin: 0 15px;
          @include Font(16, 400, 1);
          background: nth($color, 5);
          padding: 8px 25px;
          @include Tran(0.4s, ease-in-out);
          @include BorRadius(25px);
          &:hover {
            color: nth($color, 1);
            background: nth($color, 4);
          }
        }
        button {
          @extend a;
          border: 0;
        }
        .MuiListItemText-root {
          margin: 0;
          span {
            color: nth($color, 1);
          }
          p {
            @extend span;
            display: none;
          }
        }
        .MuiListItem-gutters {
          padding: 8px 25px;
        }
      }
      .CreateCVDiv {
        position: relative;
        .CreateArea {
          position: relative;
          background: nth($color, 1);
          box-shadow: 0 0px 6px rgba(147, 39, 143, 0.2);
          @include BorRadius(10px);
          padding: 25px;
          h3 {
            @include Font(22, 600, 4);
            margin-bottom: 15px;
          }
          .FormSteps {
            position: relative;
            .FormInnerDiv {
              max-width: 650px;
              margin: 30px auto 50px;
              float: none;
              background: nth($color, 1);
              box-shadow: 0 0px 6px rgba(147, 39, 143, 0.2);
              @include BorRadius(10px);
              padding: 25px;
              h4 {
                @include Font(22, 400, 4);
                margin-bottom: 30px;
              }
              form {
                padding: 0 30px;
                .MuiFormControl-root {
                  width: 100%;
                  margin: 0;
                  .MuiInputBase-root {
                    &::after,
                    &::before {
                      display: none;
                    }
                  }
                }
                label {
                  @include Font(16, 400, 3);
                }
                span {
                  @include Font(14, 400, 3);
                }
                input {
                  width: 100%;
                  background: transparent;
                  padding: 8px 15px;
                  border: 0;
                  background: #f2f2f2;
                  @include Font(16, 400, 3);
                  @include BorRadius(25px);
                }
                .MuiButtonBase-root {
                  width: 100%;
                  background: transparent;
                  padding: 8px 15px;
                  border: 0;
                  background: #f2f2f2;
                  @include Font(16, 400, 3);
                  @include BorRadius(25px);
                  .MuiButton-label {
                    justify-content: flex-start;
                  }
                }
                .CheckBox {
                  position: relative;
                  .MuiButtonBase-root {
                    width: auto;
                    background: transparent;
                  }
                }
                textarea {
                  @extend input;
                  resize: none;
                  height: 100px;
                }
                select {
                  width: 100%;
                  background: transparent;
                  padding: 8px 15px;
                  background: #f2f2f2;
                  border: none;
                  @include Font(16, 400, 3);
                  @include BorRadius(25px);
                  appearance: inherit;
                  cursor: pointer;
                  background-repeat: no-repeat, repeat;
                  background-position: right 1.5em top 50%, 0 0;
                  background-size: -0.25em auto, 100%;
                }
                .UploadImgDiv {
                  width: 100%;
                  position: relative;
                  height: auto;
                  label {
                    position: relative;
                    display: block;
                    background: #f2f2f2;
                    padding: 8px 15px;
                    @include Font(16, 300, 3);
                    @include BorRadius(25px);
                    border: 0;
                    cursor: pointer;
                    background-repeat: no-repeat, repeat;
                    background-position: right 1.5em top 50%, 0 0;
                    background-size: -0.25em auto, 100%;
                  }
                  .UploadImg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 99;
                    opacity: 0;
                    cursor: pointer;
                  }
                }
                .AddBtn {
                  border: 0;
                  background: transparent;
                  @include Font(16, 600, 3);
                  .icon {
                    color: nth($color, 4);
                  }
                }
              }
              .UploadImgDiv {
                width: 80%;
                margin: 0 auto;
                float: none;
                .ImgDiv {
                  position: relative;
                  width: 150px;
                  margin: 0 auto 30px;
                  padding: 5px;
                  background: nth($color, 1);
                  box-shadow: 0px 0px 10px rgba(147, 39, 143, 0.25);
                  @include BorRadius(10px);
                  .CrossBtn {
                    position: absolute;
                    top: -15px;
                    right: -15px;
                    .icon {
                      font-size: 20px;
                      color: nth($color, 3);
                      cursor: pointer;
                    }
                  }
                }
                .progress {
                  width: 100%;
                  padding: 5px;
                  position: relative;
                  margin-bottom: 30px;
                  background: #f2f2f2;
                  @include BorRadius(10px);
                  overflow: visible;
                  .progress-bar {
                    @include BorRadius(10px);
                    background-color: nth($color, 3);
                  }
                  .CrossBtn {
                    position: absolute;
                    top: 0px;
                    right: -30px;
                    .icon {
                      font-size: 20px;
                      color: nth($color, 3);
                      cursor: pointer;
                    }
                  }
                }
              }
            }
            .FormBtnDiv {
              .BackBtn {
                margin: 0 15px;
                @include Font(16, 400, 1);
                background: nth($color, 5);
                padding: 8px 25px;
                @include Tran(0.4s, ease-in-out);
                @include BorRadius(25px);
                &:hover {
                  color: nth($color, 1);
                  background: nth($color, 4);
                }
              }
              .SubmitBtn {
                margin: 0 15px;
                @include Font(16, 400, 1);
                background: nth($color, 5);
                padding: 8px 25px;
                @include Tran(0.4s, ease-in-out);
                @include BorRadius(25px);
                &:hover {
                  color: nth($color, 1);
                  background: nth($color, 4);
                }
              }
            }
          }
        }
      }
      .PostCVDiv {
        position: relative;
        .PostArea {
          position: relative;
          background: nth($color, 1);
          box-shadow: 0 0px 6px rgba(147, 39, 143, 0.2);
          @include BorRadius(10px);
          padding: 25px;
          h3 {
            @include Font(24, 600, 4);
            margin-bottom: 50px;
          }
          .PostCategoryDiv {
            position: relative;
            margin-bottom: 50px;
            h2 {
              @include Font(26, 600, 4);
              margin-bottom: 15px;
            }
            select {
              padding: 10px 50px 10px 5px;
              border: 0;
              border-bottom: 1px solid nth($color, 5);
              @include Font(18, 400, 3);
              appearance: inherit;
              cursor: pointer;
              background-repeat: no-repeat, repeat;
              background-position: right 0.5em top 50%, 0 0;
              background-size: -0.25em auto, 100%;
            }
          }
        }
      }
      .CandidateDetailDiv {
        position: relative;
        background: nth($color, 1);
        box-shadow: 0 0px 6px rgba(147, 39, 143, 0.2);
        @include BorRadius(10px);
        margin-bottom: 30px;
        padding: 25px;
        .Title {
          position: relative;
          h3 {
            @include Font(32, 600, 4);
            margin-bottom: 5px;
          }
        }
        .BreadCrumbUl {
          justify-content: flex-start;
          margin-bottom: 30px;
          li {
            position: relative;
            @include Font(16, 600, 3);
            margin: 0 5px 10px;
            padding-right: 10px;
            &::before {
              content: '>';
              position: absolute;
              top: 1px;
              right: -10px;
              width: 15px;
              height: 15px;
            }
          }
        }
        .CandidateInfo {
          position: relative;
          margin-bottom: 30px;
          .CandidateImg {
            width: 200px;
          }
          .CandidateText {
            width: calc(100% - 210px);
            .CandInfo {
              width: auto;
              h2 {
                @include Font(18, 600, 4);
                margin-bottom: 5px;
              }
              p {
                @include Font(16, 400, 3);
              }
            }
            .InfoUl {
              position: relative;
              width: auto;
              a {
                @include Flex(center, center);
                width: 40px;
                height: 40px;
                margin: 0 5px;
                background: nth($color, 1);
                box-shadow: 2px 2px 4px rgba(147, 39, 143, 0.2);
                @include BorRadius(50%);
                @include Tran(0.4s, ease-in-out);
                &:hover {
                  background: nth($color, 4);
                  .icon {
                    @include Tran(0.4s, ease-in-out);
                    color: nth($color, 1);
                  }
                  .heart {
                    color: nth($color, 1);
                  }
                }
                .icon {
                  font-size: 18px;
                  color: nth($color, 4);
                }
                .heart {
                  font-size: 18px;
                  color: nth($color, 5);
                }
              }
              .MuiFormControl-root {
                max-width: 200px;
                width: 200px;
                background: #f2f2f2;
                padding: 5px 15px;
                @include Font(16, 400, 3);
                @include BorRadius(25px);
                .MuiInputBase-root {
                  &::before,
                  &::after {
                    display: none;
                  }
                  .MuiSelect-icon {
                    color: nth($color, 5);
                  }
                }
              }
            }
            .EduUl {
              position: relative;
              margin: 10px 0 30px;
              li {
                @include Font(16, 600, 4);
                span {
                  margin-left: 10px;
                  @include Font(16, 400, 3);
                }
              }
            }
            .SocailOl {
              position: relative;
              li {
                @include Font(16, 600, 4);
                span {
                  margin-left: 10px;
                  @include Font(16, 400, 3);
                }
              }
            }
          }
        }
        .CandidateDetail {
          h4 {
            @include Font(22, 600, 4);
            margin: 15px 0 10px;
          }
          p {
            @include Font(16, 400, 3);
            margin-bottom: 15px;
          }
          .DetUl {
            position: relative;
            li {
              @include Font(16, 400, 3);
              margin-bottom: 5px;
            }
          }
          .ListDiv {
            span {
              @include Font(16, 400, 3);
              margin: 0 5px;
            }
          }
        }
      }
    }
    .RightSideBar {
      width: 280px;
      .RightSideSearch {
        position: relative;
        background: nth($color, 1);
        padding: 25px;
        box-shadow: 0 2px 11px rgba(147, 39, 143, 0.2);
        @include BorRadius(10px);
        &.BorderRad {
          @include BorRadius(25px);
        }
        margin-bottom: 30px;
        form {
          input[type='text'] {
            width: 100%;
            background: #f3f3f3;
            padding: 8px 15px;
            margin-bottom: 15px;
            border: 0;
            @include Font(14, 400, 3);
            @include BorRadius(25px);
            &.CustomInput {
              width: 90%;
              display: block;
              margin: 15px auto;
              background: transparent;
              border: 1px solid nth($color, 3);
              @include BorRadius(5px);
            }
          }
          .MuiSlider-root {
            color: nth($color, 4);
          }
          p.MuiTypography-root {
            @include Font(14, 400, 3);
            span {
              color: nth($color, 5);
            }
          }
          select {
            position: relative;
            width: 100%;
            background: #f3f3f3;
            padding: 8px 15px;
            border: 0;
            appearance: inherit;
            @include Font(14, 400, 3);
            @include BorRadius(25px);
            cursor: pointer;
            background-repeat: no-repeat, repeat;
            background-position: right 1.5em top 50%, 0 0;
            background-size: -0.25em auto, 100%;
          }
          .MuiFormControlLabel-root {
            display: block;
            margin: 0;
          }
          .SizesofCar {
            li {
              text-align: center;
              margin: 10px 0;
              img {
                width: auto;
                height: 30px;
                margin-bottom: 5px;
              }
              p {
                @include Font(14, 400, 3);
              }
            }
          }
        }
        .RightSideJob {
          padding: 40px 25px;
          .OpenJobImg {
            display: block;
            margin: 0 auto 20px;
          }
          h4 {
            @include Font(18, 600, 4);
            margin-bottom: 15px;
          }
          p {
            @include Font(16, 400, 3);
            margin-bottom: 15px;
          }
          .SideOl {
            position: relative;
            margin-bottom: 30px;
            a {
              @include Flex(center, center);
              width: 40px;
              height: 40px;
              margin: 0 5px;
              background: nth($color, 1);
              box-shadow: 2px 2px 4px rgba(147, 39, 143, 0.2);
              @include BorRadius(50%);
              @include Tran(0.4s, ease-in-out);
              &:hover {
                background: nth($color, 4);
                .icon {
                  @include Tran(0.4s, ease-in-out);
                  color: nth($color, 1);
                }
                .heart {
                  color: nth($color, 1);
                }
              }
              .icon {
                font-size: 18px;
                color: nth($color, 4);
              }
              .heart {
                font-size: 18px;
                color: nth($color, 5);
              }
            }
          }
          .SideUl {
            position: relative;
            li {
              @include Flex(space-between, center);
              margin-bottom: 15px;
              .ImgDiv {
                width: 60px;
                height: auto;
              }
              .TextDiv {
                width: calc(100% - 75px);
                height: auto;
                h5 {
                  @include Font(14, 400, 4);
                  margin-bottom: 5px;
                }
                p {
                  @include Font(14, 400, 3);
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
      .RelatedSearch {
        h4 {
          margin-bottom: 30px;
          @include Font(20, 400, 4);
        }
      }
    }
    .SocialLinksDiv {
      position: relative;
      background: nth($color, 1);
      padding: 25px;
      box-shadow: 0 2px 11px rgba(147, 39, 143, 0.2);
      @include BorRadius(10px);
      margin-bottom: 30px;
      p {
        @include Font(14, 400, 3);
        margin-bottom: 15px;
      }
      .SocialLinks {
        a {
          width: 30px;
          height: 30px;
          margin: 0 5px;
          @include BorRadius(50%);
        }
      }
    }
  }
  .Message {
    @include Font(16, 600, 4);
  }
  .BreadCrumbUl {
    justify-content: flex-start;
    margin-bottom: 50px;
    padding-left: 50px;
    @include Media(1366) {
      margin-bottom: 30px;
      padding-left: 0;
    }
    li {
      position: relative;
      @include Font(16, 600, 3);
      margin: 0 5px 10px;
      padding-right: 10px;
      &::before {
        content: '>';
        position: absolute;
        top: 1px;
        right: -10px;
        width: 15px;
        height: 15px;
      }
    }
  }
}

.MuiList-root {
  padding: 5px 10px !important;
}

.MuiIconButton-label {
  svg {
    fill: nth($color, 4);
  }
}

.ModalInnerDiv {
  position: relative;
  .ModalText {
    text-align: center;
    h6 {
      @include Font(20, 600, 4);
      margin-bottom: 15px;
    }
    p {
      @include Font(16, 400, 3);
      margin-bottom: 30px;
      span {
        @include Font(16, 600, 4);
      }
    }
    img {
      display: block;
      margin: 0 auto 15px;
    }
    .TextUl {
      width: 80%;
      float: none;
      margin: 0 auto;
      li {
        margin-bottom: 15px;
        @include Font(14, 400, 3);
        span {
          margin-right: 10px;
          @include Font(16, 400, 4);
        }
      }
    }
  }
  .ModalBtnDiv {
    button {
      padding: 10px 50px;
      background: nth($color, 5);
      border: 0;
      margin: 0 15px;
      @include Font(18, 400, 1);
      @include BorRadius(25px);
      @include Tran(0.4s, ease-in-out);
      &:hover {
        background: #8e1046;
      }
    }
    a {
      @extend button;
      padding: 10px 30px;
      @include Font(16, 400, 1);
      display: inline-block;
    }
  }
}
