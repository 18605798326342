@import '../../index.scss';

.ClassifiedWrapper {
  position: relative;
  padding: 0 0 100px;
  .ClassifiedUl {
    position: relative;
    li {
      position: relative;
      padding: 10px 10px;
      @include Font(14, 400, 3);
      border-bottom: 1px solid nth($color, 3);
      .icon {
        position: absolute;
        top: 15px;
        right: 15px;
        font-size: 14px;
        color: #c85084;
      }
    }
  }
  .MainComponent {
    position: relative;
    padding: 15px 0 70px;
    .BreadUl {
      justify-content: flex-start;
      margin: 0 15px;
      li {
        position: relative;
        @include Font(14, 600, 3);
        margin: 0 5px 10px;
        padding-right: 10px;
        &::before {
          content: '>';
          position: absolute;
          top: 1px;
          right: -10px;
          width: 15px;
          height: 15px;
        }
      }
    }
    p.Result {
      @include FitContent();
      @include Font(14, 300, 3);
      margin: 0 15px;
      margin-bottom: 20px;
      border-bottom: 1px solid nth($color, 5);
    }
    .FilterUl {
      position: relative;
      margin-bottom: 15px;
      li {
        width: 50%;
        padding: 5px;
        text-align: center;
        border-top: 1px solid #f2f2f2;
        border-bottom: 1px solid #f2f2f2;
        &:first-child {
          border-right: 1px solid #f2f2f2;
        }
        div {
          z-index: 999;
          .MuiListItem-root {
            width: 100%;
            border: 0;
            min-height: auto;
          }
        }
        button {
          background: transparent;
          border: 0;
          padding: 0;
          min-width: auto;
          min-height: auto;
          line-height: 26px;
          @include Font(16, 400, 3);
          .icon {
            margin-right: 5px;
            color: nth($color, 4);
          }
        }
        .makeStyles-root-1 {
          @include FitContent();
          margin: 0 auto;
          div {
            z-index: 999;
          }
          li {
            width: 100%;
            padding: 5px;
            border: 0;
            min-height: auto;
            @include Font(16, 400, 3);
          }
        }
      }
    }
    .MostPopularDiv {
      position: relative;
      padding: 0 15px;
      h2 {
        @include Font(20, 700, 4);
        margin-bottom: 30px;
      }
    }
  }
  button.MuiButtonBase-root {
    margin: 0 auto;
  }
  .PlaceAdsWrapper {
    position: relative;
    .Title {
      padding: 0 10px;
      margin-bottom: 15px;
      .BreadCrumb {
        margin: 15px 0 0;
        li {
          position: relative;
          @include Font(12, 600, 3);
          margin: 5px;
          padding-right: 10px;
          &::before {
            content: '>';
            position: absolute;
            top: 1px;
            right: -10px;
            width: 15px;
            height: 15px;
          }
          &:last-child::before {
            display: none;
          }
        }
      }
    }
    .AdsFormOuterDiv {
      position: relative;
      padding: 0 10px;
      .FormHeading {
        position: relative;
        margin-bottom: 30px;
        span.CustomSpan {
          @include Flex(center, center);
          width: 30px;
          height: 30px;
          background: nth($color, 1);
          box-shadow: 0px 0px 10px rgba(147, 39, 143, 0.25);
          margin-right: 15px;
          @include Font(16, 600, 3);
          @include BorRadius(50%);
        }
        h3 {
          width: calc(100% - 55px);
          @include Font(18, 600, 3);
          small {
            display: block;
          }
        }
        .MuiFormControlLabel-label {
          @include Font(14, 600, 3);
        }
        .NextBtnDiv {
          margin-top: 50px;
          button {
            padding: 10px 50px;
            background: nth($color, 5);
            border: 0;
            @include Font(18, 400, 1);
            @include BorRadius(25px);
            @include Tran(0.4s, ease-in-out);
            &:hover {
              background: #8e1046;
            }
          }
        }
      }
      .FormDescription {
        position: relative;
        text-align: center;
        .SubCategoriesUl {
          width: 575px;
          margin: 0 auto;
          float: none;
          li {
            text-align: left;
            position: relative;
            padding: 10px 0;
            border-bottom: 1px solid nth($color, 3);
            @include Font(18, 400, 3);
            cursor: pointer;
            .icon {
              position: absolute;
              top: 15px;
              right: 5px;
              font-size: 18px;
              color: nth($color, 5);
            }
          }
        }
        form {
          width: 100%;
          padding: 0 10px;
          margin: 0 auto 30px;
          float: none;
          label {
            @include Font(14, 400, 3);
            text-align: left;
            display: block;
          }
          input {
            width: 100%;
            background: transparent;
            border: 1px solid nth($color, 3);
            padding: 8px 15px;
            @include Font(14, 400, 3);
            @include BorRadius(10px);
            &:focus {
              outline: none;
            }
          }
          textarea {
            @extend input;
            height: 150px;
            resize: none;
          }
          select {
            @extend input;
            appearance: inherit;
            cursor: pointer;
            background-repeat: no-repeat, repeat;
            background-position: right 1.5em top 50%, 0 0;
            background-size: -0.25em auto, 100%;
          }
          .EditBtn {
            background: #f2f2f2;
            position: absolute;
            top: 5px;
            right: -35px;
            border: 0;
            padding: 2px 8px;
            @include BorRadius(50%);
            .icon {
              font-size: 12px;
            }
          }
          .UploadImgDiv {
            position: relative;
            height: auto;
            label {
              position: relative;
              display: block;
              background: #f2f2f2;
              padding: 8px 15px;
              @include Font(16, 300, 3);
              @include BorRadius(25px);
              border: 0;
              cursor: pointer;
              background-repeat: no-repeat, repeat;
              background-position: right 1.5em top 50%, 0 0;
              background-size: -0.25em auto, 100%;
            }
            .UploadImg {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: 99;
              opacity: 0;
              cursor: pointer;
            }
          }
        }
        .UploadImgDiv {
          width: 100%;
          margin: 0 auto;
          float: none;
          .ImgDiv {
            position: relative;
            width: 150px;
            margin: 0 auto 30px;
            padding: 5px;
            background: nth($color, 1);
            box-shadow: 0px 0px 10px rgba(147, 39, 143, 0.25);
            @include BorRadius(10px);
            .CrossBtn {
              position: absolute;
              top: -15px;
              right: -15px;
              .icon {
                font-size: 20px;
                color: nth($color, 3);
                cursor: pointer;
              }
            }
          }
          .progress {
            width: 90%;
            padding: 5px;
            position: relative;
            margin-bottom: 30px;
            background: #f2f2f2;
            @include BorRadius(10px);
            overflow: visible;
            .progress-bar {
              @include BorRadius(10px);
              background-color: nth($color, 3);
            }
            .CrossBtn {
              position: absolute;
              top: 0px;
              right: -30px;
              .icon {
                font-size: 20px;
                color: nth($color, 3);
                cursor: pointer;
              }
            }
          }
        }
        .LocationDiv {
          width: 100%;
          margin: 0 auto;
          float: none;
          p {
            @include Font(16, 400, 3);
            margin-bottom: 10px;
          }
          span {
            display: block;
            @include Font(16, 300, 3);
            margin-bottom: 30px;
          }
          iframe {
            width: 100%;
            height: 300px;
            border: 0;
            margin-bottom: 50px;
            @include BorRadius(25px);
          }
        }
        .MuiFormControl-root {
          width: 100%;
          margin: 0 auto 30px;
          .MuiFormLabel-root {
            @include Font(14, 400, 3);
          }
          .MuiFormGroup-root {
            flex-flow: row wrap;
            justify-content: space-around;
          }
          .MuiIconButton-label {
            svg {
              fill: nth($color, 4);
            }
          }
        }
        .AddFutureUl {
          width: 100%;
          margin: 0 auto;
          position: relative;
          li {
            text-align: left;
            width: 50%;
            .MuiFormControlLabel-label {
              @include Font(14, 400, 3);
            }
            .MuiIconButton-label {
              svg {
                fill: nth($color, 4);
              }
            }
          }
        }
        .ViewBtn {
          position: relative;
          button {
            width: 100%;
            display: block;
            text-align: left;
            border: 0;
            background: transparent;
            padding: 15px 0;
            margin-bottom: 30px;
            border-bottom: 1px solid nth($color, 3);
            @include Font(18, 600, 5);
          }
        }
      }
    }
    .DetailsComponent {
      position: relative;
      margin-bottom: 30px;
      padding: 30px 10px;
      .Title {
        position: relative;
        h3 {
          @include Font(40, 400, 4);
          @include Media(767) {
            font-size: 24px;
          }
          @include Media(575) {
            font-size: 18px;
          }
        }
        strong {
          @include Font(40, 600, 5);
          @include Media(767) {
            font-size: 24px;
          }
          @include Media(575) {
            font-size: 18px;
          }
        }
      }
      .BreadCrumbUl {
        justify-content: flex-start;
        margin-bottom: 30px;
        li {
          position: relative;
          @include Font(12, 600, 3);
          margin: 0 5px 5px;
          padding-right: 10px;
          &::before {
            content: '>';
            position: absolute;
            top: 1px;
            right: -10px;
            width: 15px;
            height: 15px;
          }
        }
      }
      .carousel {
        position: relative;
        .carousel-inner {
          width: 80%;
          margin-left: auto;
          @include Media(767) {
            width: 100%;
            margin: 0 auto 15px;
          }
          .carousel-item {
            position: relative;
            text-align: center;
            img {
              margin: 0 auto;
              display: block;
              width: auto;
              height: 400px;
              @include Media(767) {
                height: 300px;
              }
              @include Media(575) {
                height: 200px;
              }
            }
          }
        }
        .carousel-indicators {
          width: 19%;
          padding: 5px 15px 0 0;
          left: 0;
          right: auto;
          margin: 0;
          top: 0;
          height: 100%;
          overflow-y: scroll;
          flex-direction: column;
          @include Media(767) {
            display: none;
          }
          li {
            border: 0;
            width: 100%;
            height: 100px;
            text-align: center;
            padding: 5px 0;
            img {
              width: 100%;
              height: auto;
              margin: 0 auto;
              display: block;
            }
          }
        }
      }
      .ChatUl {
        position: relative;
        li {
          width: 100%;
          @include BorRadius(25px);
          border: 1px solid nth($color, 3);
          margin-bottom: 15px;
          cursor: pointer;
          @include Media(767) {
            width: 90%;
            margin: 0 auto 10px;
          }
          img {
            width: 40px;
            height: 40px;
            @include BorRadius(50%);
            box-shadow: 0 0px 6px rgba(147, 39, 143, 0.2);
          }
          span {
            padding-left: 15px;
            @include Font(16, 400, 3);
            @include Media(767) {
              font-size: 14px;
              padding-left: 10px;
            }
          }
        }
      }
      .PostedDate {
        position: relative;
        margin: 15px 0 30px;
        @include Font(16, 400, 3);
        @include Media(767) {
          font-size: 14px;
          margin: 15px 0;
        }
      }
      .Description {
        position: relative;
        margin-bottom: 30px;
        h5 {
          @include Font(18, 400, 5);
          margin-bottom: 30px;
        }
        .ExpandText {
          position: relative;
          span {
            @include Font(16, 400, 3);
            @include Media(767) {
              font-size: 14px;
            }
          }
          p {
            @include Font(16, 400, 3);
            @include Media(767) {
              font-size: 14px;
            }
          }
          a {
            display: block;
            padding: 15px 0;
            @include Font(15, 600, 5);
            border-bottom: 1px solid #bfbebe;
          }
        }
      }
      .DetailsListDiv {
        .DetailsListItem {
          position: relative;
          h6 {
            margin-bottom: 15px;
            @include Font(20, 400, 5);
            @include Media(767) {
              font-size: 18px;
            }
          }
          .ListDetailsUl {
            position: relative;
            margin-bottom: 30px;
            li {
              span {
                width: 50%;
                display: inline-block;
                @include Font(15, 400, 3);
                @include Media(767) {
                  font-size: 12px;
                }
                img {
                  width: 15px;
                  margin-right: 10px;
                }
              }
              b {
                width: 50%;
                display: inline-block;
                @include Font(15, 600, 3);
                @include Media(767) {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
      .LocationDiv {
        position: relative;
        h6 {
          margin-bottom: 15px;
          @include Font(19, 400, 5);
        }
        h5 {
          margin-bottom: 15px;
          @include Font(18, 600, 4);
        }
        .LocationIframe {
          position: relative;
          iframe {
            width: 100%;
            height: 400px;
            border: 0;
            @include BorRadius(10px);
            box-shadow: 0 0px 6px rgba(147, 39, 143, 0.2);
            @include Media(767) {
              height: 300px;
              margin-bottom: 30px;
            }
          }
        }
      }
      .RelatedLocDiv {
        box-shadow: 0 0px 6px rgba(147, 39, 143, 0.2);
        @include BorRadius(25px);
        p {
          background: #efefef;
          text-align: center;
          padding: 15px 0;
          @include BorRadius(25px);
          @include Font(19, 400, 4);
          @include Media(767) {
            font-size: 16px;
            padding: 10px 0;
          }
        }
        .LocUl {
          display: block;
          position: relative;
          width: 100%;
          background: nth($color, 1);
          margin: 15px 0;
          li {
            margin: 5px 0px 5px 30px;
            @include Font(15, 400, 3);
          }
        }
      }
      .EmailerModal {
        .modal-dialog {
          max-width: 700px;
          box-shadow: 0 0px 6px rgba(147, 39, 143, 0.2);
          h5 {
            @include Font(22, 600, 4);
          }
        }
        .modal-header {
          border: 0;
          padding: 15px;
        }
        .form {
          label {
            display: block;
            text-align: right;
            padding: 10px 0;
            @include Font(18, 300, 3);
            @include Media(767) {
              font-size: 14px;
              text-align: left;
              padding-bottom: 0;
            }
          }
          input {
            width: 100%;
            padding: 10px;
            border: 1px solid #e6e6e6;
            @include Font(16, 400, 3);
            @include BorRadius(25px);
            @include Media(767) {
              font-size: 14px;
            }
          }
          textarea {
            @extend input;
            resize: none;
            height: 150px;
          }
          .EmailBtn {
            padding: 10px 50px;
            border: 0;
            background: nth($color, 5);
            @include BorRadius(25px);
            @include Font(18, 400, 1);
          }
        }
      }
    }
    .BtnDiv {
      button {
        padding: 10px 50px;
        background: nth($color, 5);
        border: 0;
        margin: 0 15px;
        @include Font(18, 400, 1);
        @include BorRadius(25px);
        @include Tran(0.4s, ease-in-out);
        &:hover {
          background: #8e1046;
        }
      }
    }
    .ModalInnerDiv {
      position: relative;
      .ModalText {
        text-align: center;
        h6 {
          @include Font(18, 600, 4);
          margin-bottom: 10px;
        }
        p {
          @include Font(14, 400, 3);
          margin-bottom: 30px;
          span {
            @include Font(16, 600, 4);
          }
        }
        img {
          width: 50px;
          display: block;
          margin: 0 auto 30px;
        }
        .TextUl {
          width: 100%;
          float: none;
          margin: 0 auto;
          li {
            margin-bottom: 15px;
            @include Font(14, 400, 3);
            span {
              margin-right: 10px;
              @include Font(14, 400, 4);
            }
          }
        }
      }
      .ModalBtnDiv {
        button {
          padding: 10px 50px;
          background: nth($color, 5);
          border: 0;
          margin: 0 15px;
          @include Font(18, 400, 1);
          @include BorRadius(25px);
          @include Tran(0.4s, ease-in-out);
          &:hover {
            background: #8e1046;
          }
        }
        a {
          @extend button;
          padding: 10px 30px;
          margin: 10px 0;
          @include Font(16, 400, 1);
          display: inline-block;
        }
      }
    }
  }
}

.FilterBar {
  position: fixed;
  left: 0;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background: nth($color, 1);
  z-index: 99;
  .FilterDiv {
    position: relative;
    form {
      position: relative;
      .form-row {
        padding: 15px;
      }
      label {
        @include Font(16, 600, 4);
        margin-bottom: 5px;
      }
      select {
        position: relative;
        width: 100%;
        background: transparent;
        padding: 8px 15px;
        border: 1px solid nth($color, 3);
        appearance: inherit;
        @include Font(14, 600, 4);
        @include BorRadius(10px);
        cursor: pointer;
        background-repeat: no-repeat, repeat;
        background-position: right 1.5em top 50%, 0 0;
        background-size: -0.25em auto, 100%;
      }
      input {
        width: 100%;
        background: transparent;
        padding: 8px 15px;
        border: 1px solid nth($color, 3);
        margin-bottom: 15px;
        @include Font(14, 400, 3);
        @include BorRadius(10px);
      }
      .FilterInnerUl {
        position: relative;
        li {
          width: auto;
          padding: 5px 10px;
          margin: 0 5px 5px 0;
          border: 1px solid nth($color, 3);
          @include Font(14, 400, 3);
          @include BorRadius(25px);
        }
      }
      .FilterBtnDiv {
        padding: 10px;
        background: nth($color, 1);
        box-shadow: 0px -2px 4px rgba(147, 37, 143, 0.25);
        button {
          padding: 8px 50px;
          border: 0;
          margin: 0 auto;
          background: nth($color, 5);
          @include Font(16, 400, 1);
          @include BorRadius(25px);
        }
      }
    }
  }
}
