@import '../../../index.scss';

header {
  position: relative;
  background: nth($color, 1);
  padding: 10px;
  box-shadow: 2px 2px 4px rgba(147, 43, 137, 0.25);
  .MuiButtonBase-root {
    padding: 0;
    min-width: 32px;
  }
  .Logo {
    float: none;
    width: 100px;
  }
  .nav-item {
    position: relative;
    .nav-link {
      @include Font(16, 400, 3);
      color: nth($color, 3) !important;
      .UserIcon {
        font-size: 20px;
        color: nth($color, 5);
      }
    }
  }
  .dropdown-menu {
    position: absolute;
    right: 0 !important;
    top: 24% !important;
    left: auto !important;
    transform: translate3d(0, 40px, 0px) !important;
    padding: 25px 30px;
    border: 0;
    box-shadow: 0 3px 6px rgba(147, 39, 143, 0.2);
    &.Top {
      top: 45% !important;
    }
    input {
      border: 0;
      width: 200px;
      padding: 5px;
      border-bottom: 1px solid nth($color, 4);
      @include Font(16, 400, 3);
      background: transparent !important;
    }
    .FormBtn {
      width: 100%;
      text-align: center;
      border: 0;
      background: nth($color, 5);
      @include Font(16, 400, 1);
      padding: 10px 0;
      @include BorRadius(15px);
    }
    .Border {
      border-bottom: 1px solid nth($color, 3);
    }
    .FormOuterUl {
      li {
        width: 100%;
      }
      a {
        @include Font(14, 600, 4);
        @include Tran(0.8s, ease-in-out);
        &:hover {
          color: nth($color, 5);
        }
      }
      p {
        @include Font(14, 400, 3);
      }
    }
  }
  .dropdown-toggle::after {
    border-top: 0.3em solid nth($color, 5);
  }
  .ProfileBtn {
    background: transparent;
    border: 0;
    padding: 0 5px;
    @include Font(14, 400, 5);
    .icon {
      display: block;
    }
    span {
      color: nth($color, 4);
    }
  }
  .FormLoginUl {
    width: 200px;
    position: relative;
    a {
      @include Flex(flex-start, center);
      position: relative;
      padding: 10px 25px;
      @include Font(16, 700, 4);
      img {
        width: 20px;
        margin-right: 15px;
      }
      &:hover,
      &.active {
        background: rgba(147, 39, 143, 0.1);
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 10px;
          height: 100%;
          background: nth($color, 5);
        }
      }
    }
  }
}
