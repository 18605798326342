@import '../../../index.scss';

.CandidateCom {
  position: relative;
  padding: 30px 30px 100px 0;
  .CompDiv {
    position: relative;
    align-items: flex-start;
    .LeftSideBar {
      width: 100px;
      background: nth($color, 1);
      box-shadow: 0 0px 6px rgba(147, 39, 143, 0.2);
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      overflow: hidden;
      margin-top: 170px;
      li {
        position: relative;
        a {
          display: block;
          text-align: center;
          padding: 30px;
          &.active {
            background: rgba(147, 39, 143, 0.2);
          }
          &:hover {
            background: rgba(147, 39, 143, 0.2);
          }
        }
      }
    }
    .MainComponent {
      width: 1140px;
      @include Media(1600) {
        width: 900px;
      }
      .MainComDiv {
        position: relative;
        .SearchCom {
          text-align: center;
          background: nth($color, 1);
          box-shadow: 0 0px 6px rgba(147, 39, 143, 0.2);
          @include BorRadius(10px);
          margin-bottom: 30px;
          padding: 25px;
          h3 {
            @include Font(27, 600, 3);
            margin-bottom: 15px;
            span {
              color: nth($color, 5);
            }
          }
          .SearchDiv {
            form {
              input {
                width: 100%;
                background: transparent;
                padding: 8px 15px;
                border: 1px solid #c9c9c9;
                @include Font(16, 400, 3);
                @include BorRadius(15px);
                appearance: inherit;
                cursor: pointer;
                background-repeat: no-repeat, repeat;
                background-position: right 1.5em top 50%, 0 0;
                background-size: -0.25em auto, 100%;
              }
              select {
                width: 100%;
                background: transparent;
                padding: 8px 15px;
                border: 1px solid #c9c9c9;
                @include Font(16, 400, 3);
                @include BorRadius(15px);
                appearance: inherit;
                cursor: pointer;
                background-repeat: no-repeat, repeat;
                background-position: right 1.5em top 50%, 0 0;
                background-size: -0.25em auto, 100%;
              }
              .SearchBtn {
                width: 100%;
                background: transparent;
                padding: 8px 15px;
                background: nth($color, 5);
                border: 0;
                @include Font(20, 400, 1);
                @include BorRadius(25px);
                @include Tran(0.4s, ease-in-out);
                &:hover {
                  background: #af3a6c;
                }
              }
            }
          }
        }
        .ListImg {
          img {
            width: auto;
            height: 250px;
            object-fit: cover;
          }
        }
        .ListItemsDiv {
          position: relative;
          margin-bottom: 30px;
          .ListItemUl {
            position: relative;
            li {
              margin-bottom: 10px;
              padding: 0 10px;
              line-height: 18px;
              cursor: pointer;
              border-right: 1px solid nth($color, 3);
              @include Font(16, 400, 3);
              &:last-child {
                border: 0;
              }
              img {
                width: 20px;
                margin-right: 5px;
              }
            }
          }
        }
        .ItemsOuterDiv {
          position: relative;
          .ItemText {
            position: relative;
            .BreadCrumb {
              justify-content: flex-start;
              li {
                position: relative;
                @include Font(16, 600, 3);
                margin: 0 5px 10px;
                padding-right: 10px;
                &::before {
                  content: '>';
                  position: absolute;
                  top: 1px;
                  right: -10px;
                  width: 15px;
                  height: 15px;
                }
              }
            }
            p {
              @include FitContent();
              @include Font(18, 300, 3);
              margin-bottom: 20px;
              border-bottom: 1px solid nth($color, 5);
            }
            .Tabs {
              position: relative;
              .FilterTab {
                position: relative;
                li {
                  @include Font(16, 300, 3);
                  background: transparent;
                  padding: 8px 25px;
                  @include Tran(0.4s, ease-in-out);
                  @include BorRadius(25px);
                  cursor: pointer;
                  &.active,
                  &:hover {
                    background: #f3f3f3;
                    color: nth($color, 5);
                  }
                }
              }
              .GridTab {
                position: relative;
                li {
                  font-size: 20px;
                  margin: 0 5px;
                  color: nth($color, 3);
                  @include Tran(0.4s, ease-in-out);
                  cursor: pointer;
                  &.active,
                  &:hover {
                    color: nth($color, 5);
                  }
                  &:focus {
                    outline: none;
                  }
                }
              }
            }
          }
          .ItemDesc {
            position: relative;
            margin-bottom: 50px;
            .ItemsDiv {
              h4 {
                @include Font(24, 400, 4);
                margin: 30px 0;
              }
            }
          }
        }
      }
      .GoBack {
        position: relative;
        padding-left: 25px;
        display: block;
        @include Font(16, 600, 3);
        margin-bottom: 15px;
        &:before {
          content: '<<';
          position: absolute;
          top: 0;
          left: 0;
          width: 30px;
        }
      }
      .CandidateDetailDiv {
        position: relative;
        background: nth($color, 1);
        box-shadow: 0 0px 6px rgba(147, 39, 143, 0.2);
        @include BorRadius(10px);
        margin-bottom: 30px;
        padding: 25px;
        .Title {
          position: relative;
          h3 {
            @include Font(32, 600, 4);
            margin-bottom: 5px;
          }
        }
        .BreadCrumbUl {
          justify-content: flex-start;
          margin-bottom: 30px;
          li {
            position: relative;
            @include Font(16, 600, 3);
            margin: 0 5px 10px;
            padding-right: 10px;
            &::before {
              content: '>';
              position: absolute;
              top: 1px;
              right: -10px;
              width: 15px;
              height: 15px;
            }
          }
        }
        .CandidateInfo {
          position: relative;
          margin-bottom: 30px;
          .CandidateImg {
            width: 200px;
          }
          .CandidateText {
            width: calc(100% - 210px);
            .CandInfo {
              width: auto;
              h2 {
                @include Font(18, 600, 4);
                margin-bottom: 5px;
              }
              p {
                @include Font(16, 400, 3);
              }
            }
            .InfoUl {
              position: relative;
              width: auto;
              a {
                @include Flex(center, center);
                width: 40px;
                height: 40px;
                margin: 0 5px;
                background: nth($color, 1);
                box-shadow: 2px 2px 4px rgba(147, 39, 143, 0.2);
                @include BorRadius(50%);
                @include Tran(0.4s, ease-in-out);
                &:hover {
                  background: nth($color, 4);
                  .icon {
                    @include Tran(0.4s, ease-in-out);
                    color: nth($color, 1);
                  }
                  .heart {
                    color: nth($color, 1);
                  }
                }
                .icon {
                  font-size: 18px;
                  color: nth($color, 4);
                }
                .heart {
                  font-size: 18px;
                  color: nth($color, 5);
                }
              }
              .makeStyles-formControl-1 {
                max-width: 200px;
                width: 200px;
                background: #f2f2f2;
                padding: 5px 15px;
                @include Font(16, 400, 3);
                @include BorRadius(25px);
                .MuiInputBase-root {
                  &::before,
                  &::after {
                    display: none;
                  }
                }
              }
            }
            .EduUl {
              position: relative;
              margin: 10px 0 30px;
              li {
                @include Font(16, 600, 4);
                span {
                  margin-left: 10px;
                  @include Font(16, 400, 3);
                }
              }
            }
            .SocailOl {
              position: relative;
              li {
                @include Font(16, 600, 4);
                span {
                  margin-left: 10px;
                  @include Font(16, 400, 3);
                }
              }
            }
          }
        }
        .CandidateDetail {
          h4 {
            @include Font(22, 600, 4);
            margin: 15px 0 10px;
          }
          p {
            @include Font(16, 400, 3);
            margin-bottom: 15px;
          }
          .DetUl {
            position: relative;
            li {
              @include Font(16, 400, 3);
              margin-bottom: 5px;
            }
          }
          .ListDiv {
            span {
              @include Font(16, 400, 3);
              margin: 0 5px;
            }
          }
        }
      }
    }
    .RightSideBar {
      width: 280px;
      @include Media(1600) {
        width: 250px;
      }
      .RightSideSearch {
        position: relative;
        background: nth($color, 1);
        padding: 25px;
        box-shadow: 0 2px 11px rgba(147, 39, 143, 0.2);
        @include BorRadius(10px);
        &.BorderRad {
          @include BorRadius(25px);
        }
        margin-bottom: 30px;
        form {
          input[type='text'] {
            width: 100%;
            background: #f3f3f3;
            padding: 8px 15px;
            margin-bottom: 15px;
            border: 0;
            @include Font(14, 400, 3);
            @include BorRadius(25px);
            &.CustomInput {
              width: 90%;
              display: block;
              margin: 15px auto;
              background: transparent;
              border: 1px solid nth($color, 3);
              @include BorRadius(5px);
            }
          }
          .MuiSlider-root {
            color: nth($color, 4);
          }
          p.MuiTypography-root {
            @include Font(14, 400, 3);
            span {
              color: nth($color, 5);
            }
          }
          select {
            position: relative;
            width: 100%;
            background: #f3f3f3;
            padding: 8px 15px;
            border: 0;
            appearance: inherit;
            @include Font(14, 400, 3);
            @include BorRadius(25px);
            cursor: pointer;
            background-repeat: no-repeat, repeat;
            background-position: right 1.5em top 50%, 0 0;
            background-size: -0.25em auto, 100%;
          }
          .MuiFormControlLabel-root {
            display: block;
            margin: 0;
          }
        }
        .SizesofCar {
          li {
            text-align: center;
            margin: 10px 0;
            img {
              width: auto;
              height: 30px;
              margin-bottom: 5px;
              @include Media(1366) {
                height: 20px;
              }
            }
            p {
              @include Font(14, 400, 3);
              @include Media(1366) {
                font-size: 12px;
              }
            }
          }
        }
      }
      .RelatedSearch {
        h4 {
          margin-bottom: 30px;
          @include Font(20, 400, 4);
        }
      }
    }
    .SocialLinksDiv {
      position: relative;
      background: nth($color, 1);
      padding: 25px;
      box-shadow: 0 2px 11px rgba(147, 39, 143, 0.2);
      @include BorRadius(10px);
      margin-bottom: 30px;
      p {
        @include Font(14, 400, 3);
        margin-bottom: 15px;
      }
      .SocialLinks {
        a {
          width: 30px;
          height: 30px;
          margin: 0 5px;
          @include BorRadius(50%);
        }
      }
    }
  }
}

@include Webkit('carousel-indicators');
