@import '../../../index.scss';

.PlaceYourAdsArea {
  position: relative;
  .PlaceYourAdsDiv {
    position: relative;
    margin: 60px 0 300px;
    @include Media(767) {
      font-size: 24px;
      margin: 60px 0 100px;
    }
    .Title {
      position: relative;
      margin-bottom: 100px;
      @include Media(767) {
        margin-bottom: 50px;
      }
      h2 {
        @include Font(50, 700, 4);
        text-align: center;
        margin-bottom: 15px;
        @include Media(1366) {
          font-size: 40px;
        }
        @include Media(767) {
          font-size: 24px;
          margin-bottom: 5px;
        }
      }
      p {
        @include Font(24, 400, 3);
        text-align: center;
        margin-bottom: 70px;
        @include Media(1366) {
          font-size: 18px;
        }
        @include Media(991) {
          font-size: 18px;
          margin-bottom: 30px;
        }
        @include Media(767) {
          font-size: 16px;
          margin-bottom: 30px;
        }
      }
      form {
        position: relative;
        .form-group {
          position: relative;
          max-width: 70%;
          margin: 0 auto;
          @include Flex(flex-start, center);
          border: 1px solid nth($color, 3);
          @include BorRadius(25px);
          @include Media(1366) {
            max-width: 60%;
          }
          @include Media(991) {
            max-width: 100%;
          }
          @include Media(767) {
            max-width: 100%;
            justify-content: space-between;
          }
          input {
            width: calc(100% - 50px);
            border: 0;
            @include Font(18, 400, 3);
            padding: 10px 25px;
            background: transparent;
            @include Media(767) {
              padding: 8px 15px;
              font-size: 14px;
            }
          }
          .SubBtn {
            @include Flex(center, center);
            display: block;
            width: 50px;
            height: 50px;
            background: nth($color, 5);
            border: 0;
            @include BorRadius(50%);
            .icon {
              font-size: 24px;
              color: nth($color, 1);
              @include Media(767) {
                font-size: 18px;
              }
            }
            @include Media(767) {
              width: 40px;
              height: 40px;
            }
          }
        }
      }
    }
    .DescUl {
      position: relative;
      @include Media(1366) {
        justify-content: center;
      }
      .AdsItem {
        @include Flex(center, center);
        flex-direction: column;
        margin-bottom: 15px;
        width: 175px;
        background: nth($color, 1);
        padding: 40px 0;
        box-shadow: 0px 0px 10px rgba(147, 39, 143, 0.25);
        @include BorRadius(10px);
        cursor: pointer;
        img {
          width: auto;
          height: 40px;
          margin-bottom: 20px;
        }
        p {
          @include Font(22, 500, 4);
        }
      }
      .DropDown {
        @include Media(767) {
          margin: 0 auto;
        }
        &:hover {
          .DropDownMenu {
            display: block;
          }
        }
        a {
          @include Flex(center, center);
          flex-direction: column;
          margin-bottom: 15px;
          width: 175px;
          background: nth($color, 1);
          padding: 40px 0;
          box-shadow: 0px 0px 10px rgba(147, 39, 143, 0.25);
          @include BorRadius(10px);
          @include Media(1366) {
            width: 150px;
            padding: 30px 5px;
            margin: 0 15px 15px;
          }
          img {
            width: auto;
            height: 40px;
            margin-bottom: 20px;
            @include Media(767) {
              height: 35px;
              margin-bottom: 15px;
            }
          }
          p {
            @include Font(22, 500, 4);
            @include Media(1366) {
              font-size: 18px;
            }
          }
        }
      }
    }
    .AdsComponents {
      position: relative;
      @include Media(1366) {
        width: 80%;
        margin: 0 auto;
        float: none;
      }
      .PlaceAdsWrapper {
        position: relative;
        background: nth($color, 1);
        box-shadow: 0px 0px 10px rgba(147, 39, 143, 0.25);
        padding: 25px 35px 50px;
        @include BorRadius(25px);
        .Title {
          margin-bottom: 30px;
          h5 {
            @include Font(25, 600, 4);
            margin-bottom: 15px;
          }
          .BreadCrumb {
            li {
              position: relative;
              @include Font(16, 600, 3);
              margin: 0 5px 10px;
              padding-right: 10px;
              &::before {
                content: '>';
                position: absolute;
                top: 1px;
                right: -10px;
                width: 15px;
                height: 15px;
              }
            }
          }
        }
        .AdsFormOuterDiv {
          position: relative;
          .FormHeading {
            position: relative;
            margin-bottom: 30px;
            span.CustomSpan {
              @include Flex(center, center);
              width: 30px;
              height: 30px;
              background: nth($color, 1);
              box-shadow: 0px 0px 10px rgba(147, 39, 143, 0.25);
              margin-right: 15px;
              @include Font(16, 600, 3);
              @include BorRadius(50%);
            }
            h3 {
              @include Font(26, 600, 3);
            }
            .MuiFormControlLabel-label {
              @include Font(20, 600, 3);
            }
            .NextBtnDiv {
              margin-top: 50px;
              button {
                padding: 10px 50px;
                background: nth($color, 5);
                border: 0;
                @include Font(18, 400, 1);
                @include BorRadius(25px);
                @include Tran(0.4s, ease-in-out);
                &:hover {
                  background: #8e1046;
                }
              }
            }
          }
          .FormDescription {
            position: relative;
            text-align: center;
            .SubCategoriesUl {
              width: 575px;
              margin: 0 auto;
              float: none;
              li {
                text-align: left;
                position: relative;
                padding: 10px 0;
                border-bottom: 1px solid nth($color, 3);
                @include Font(18, 400, 3);
                cursor: pointer;
                .icon {
                  position: absolute;
                  top: 15px;
                  right: 5px;
                  font-size: 18px;
                  color: nth($color, 5);
                }
              }
            }
            form {
              width: 575px;
              margin: 0 auto 50px;
              float: none;
              label {
                @include Font(18, 400, 3);
                text-align: left;
                display: block;
              }
              input {
                width: 100%;
                background: #f2f2f2;
                padding: 8px 15px;
                @include Font(16, 300, 3);
                @include BorRadius(25px);
                border: 0;
                &:focus {
                  outline: none;
                }
              }
              textarea {
                @extend input;
                height: 150px;
                resize: none;
              }
              select {
                @extend input;
                appearance: inherit;
                cursor: pointer;
                background-repeat: no-repeat, repeat;
                background-position: right 1.5em top 50%, 0 0;
                background-size: -0.25em auto, 100%;
              }
              .EditBtn {
                background: #f2f2f2;
                position: absolute;
                top: 5px;
                right: -35px;
                border: 0;
                padding: 2px 8px;
                @include BorRadius(50%);
                .icon {
                  font-size: 12px;
                }
              }
              .UploadImgDiv {
                position: relative;
                height: auto;
                label {
                  position: relative;
                  display: block;
                  background: #f2f2f2;
                  padding: 8px 15px;
                  @include Font(16, 300, 3);
                  @include BorRadius(25px);
                  border: 0;
                  cursor: pointer;
                  background-repeat: no-repeat, repeat;
                  background-position: right 1.5em top 50%, 0 0;
                  background-size: -0.25em auto, 100%;
                }
                .UploadImg {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  z-index: 99;
                  opacity: 0;
                  cursor: pointer;
                }
              }
            }
            .UploadImgDiv {
              width: 60%;
              margin: 0 auto;
              float: none;
              .ImgDiv {
                position: relative;
                width: 150px;
                margin: 0 auto 30px;
                padding: 5px;
                background: nth($color, 1);
                box-shadow: 0px 0px 10px rgba(147, 39, 143, 0.25);
                @include BorRadius(10px);
                .CrossBtn {
                  position: absolute;
                  top: -15px;
                  right: -15px;
                  .icon {
                    font-size: 20px;
                    color: nth($color, 3);
                    cursor: pointer;
                  }
                }
              }
              .progress {
                width: 100%;
                padding: 5px;
                position: relative;
                margin-bottom: 30px;
                background: #f2f2f2;
                @include BorRadius(10px);
                overflow: visible;
                .progress-bar {
                  @include BorRadius(10px);
                  background-color: nth($color, 3);
                }
                .CrossBtn {
                  position: absolute;
                  top: 0px;
                  right: -30px;
                  .icon {
                    font-size: 20px;
                    color: nth($color, 3);
                    cursor: pointer;
                  }
                }
              }
            }
            .LocationDiv {
              width: 575px;
              margin: 0 auto;
              float: none;
              p {
                @include Font(16, 400, 3);
                margin-bottom: 10px;
              }
              span {
                display: block;
                @include Font(16, 300, 3);
                margin-bottom: 30px;
              }
              iframe {
                width: 100%;
                height: 300px;
                border: 0;
                margin-bottom: 50px;
                @include BorRadius(25px);
              }
            }
            .MuiFormControl-root {
              width: 575px;
              margin: 0 auto 30px;
              .MuiFormLabel-root {
                @include Font(18, 400, 3);
              }
              .MuiFormGroup-root {
                flex-flow: row wrap;
                justify-content: space-around;
              }
              .MuiIconButton-label {
                svg {
                  fill: nth($color, 4);
                }
              }
            }
            .NextBtnDiv {
              button {
                padding: 10px 50px;
                background: nth($color, 5);
                border: 0;
                @include Font(18, 400, 1);
                @include BorRadius(25px);
                @include Tran(0.4s, ease-in-out);
                &:hover {
                  background: #8e1046;
                }
              }
            }
            .AddFutureUl {
              width: 675px;
              margin: 0 auto;
              position: relative;
              li {
                text-align: left;
                width: 33%;
                .MuiFormControlLabel-label {
                  @include Font(14, 400, 3);
                }
                .MuiIconButton-label {
                  svg {
                    fill: nth($color, 4);
                  }
                }
              }
            }
            .ViewBtn {
              position: relative;
              button {
                width: 100%;
                display: block;
                text-align: left;
                border: 0;
                background: transparent;
                padding: 15px 0;
                margin-bottom: 30px;
                border-bottom: 1px solid nth($color, 3);
                @include Font(18, 600, 5);
              }
            }
          }
        }
        .DetailsComponent {
          position: relative;
          background: nth($color, 1);
          box-shadow: 0 0px 6px rgba(147, 39, 143, 0.2);
          @include BorRadius(10px);
          margin-bottom: 30px;
          padding: 25px 50px;
          .Title {
            position: relative;
            margin-bottom: 10px;
            h3 {
              @include Font(30, 400, 4);
            }
            strong {
              @include Font(30, 600, 5);
            }
          }
          .BreadCrumbUl {
            justify-content: flex-start;
            margin-bottom: 30px;
            li {
              position: relative;
              @include Font(16, 600, 3);
              margin: 0 5px 10px;
              padding-right: 10px;
              &::before {
                content: '>';
                position: absolute;
                top: 1px;
                right: -10px;
                width: 15px;
                height: 15px;
              }
            }
          }
          .carousel {
            position: relative;
            .carousel-inner {
              width: 80%;
              margin-left: auto;
              .carousel-item {
                position: relative;
                text-align: center;
                img {
                  margin: 0 auto;
                  width: 100%;
                  height: auto;
                }
              }
            }
            .carousel-indicators {
              width: 19%;
              padding: 5px 15px 0 0;
              left: 0;
              right: auto;
              margin: 0;
              top: 0;
              height: 100%;
              overflow-y: scroll;
              flex-direction: column;
              li {
                border: 0;
                width: 100%;
                height: 100px;
                text-align: center;
                padding: 5px 0;
                img {
                  width: 100%;
                  height: auto;
                  margin: 0 auto;
                  display: block;
                }
              }
            }
          }
          .ChatUl {
            position: relative;
            li {
              width: 100%;
              @include BorRadius(25px);
              border: 1px solid nth($color, 3);
              margin-bottom: 15px;
              cursor: pointer;
              img {
                width: 40px;
                height: 40px;
                @include BorRadius(50%);
                box-shadow: 0 0px 6px rgba(147, 39, 143, 0.2);
              }
              span {
                padding-left: 15px;
                @include Font(16, 400, 3);
              }
            }
          }
          .PostedDate {
            position: relative;
            margin: 15px 0 30px;
            @include Font(16, 400, 3);
          }
          .Description {
            position: relative;
            margin-bottom: 30px;
            h5 {
              @include Font(18, 400, 5);
              margin-bottom: 30px;
            }
            .ExpandText {
              position: relative;
              p {
                @include Font(16, 400, 3);
              }
              a {
                display: block;
                padding: 15px 0;
                @include Font(15, 600, 5);
                border-bottom: 1px solid #bfbebe;
              }
            }
          }
          .DetailsListDiv {
            .DetailsListItem {
              position: relative;
              h6 {
                margin-bottom: 15px;
                @include Font(20, 400, 5);
              }
              .ListDetailsUl {
                position: relative;
                margin-bottom: 30px;
                li {
                  span {
                    width: 50%;
                    display: inline-block;
                    @include Font(15, 400, 3);
                    img {
                      width: 15px;
                      margin-right: 10px;
                    }
                  }
                  b {
                    width: 50%;
                    display: inline-block;
                    @include Font(15, 600, 3);
                  }
                }
              }
            }
          }
          .LocationDiv {
            position: relative;
            h6 {
              margin-bottom: 15px;
              @include Font(19, 400, 5);
            }
            h5 {
              margin-bottom: 15px;
              @include Font(18, 600, 4);
            }
            .LocationIframe {
              position: relative;
              iframe {
                width: 100%;
                height: 400px;
                border: 0;
                @include BorRadius(10px);
                box-shadow: 0 0px 6px rgba(147, 39, 143, 0.2);
              }
            }
          }
          .RelatedLocDiv {
            box-shadow: 0 0px 6px rgba(147, 39, 143, 0.2);
            @include BorRadius(25px);
            p {
              background: #efefef;
              text-align: center;
              padding: 15px 0;
              @include BorRadius(25px);
              @include Font(19, 400, 4);
            }
            .LocUl {
              display: block;
              position: relative;
              width: 100%;
              background: nth($color, 1);
              margin: 15px 0;
              li {
                margin: 5px 0px 5px 30px;
                @include Font(15, 400, 3);
              }
            }
          }
          .EmailerModal {
            .modal-dialog {
              max-width: 700px;
              box-shadow: 0 0px 6px rgba(147, 39, 143, 0.2);
              h5 {
                @include Font(22, 600, 4);
              }
            }
            .modal-header {
              border: 0;
              padding: 15px;
            }
            .form {
              label {
                display: block;
                text-align: right;
                padding: 10px 0;
                @include Font(18, 300, 3);
              }
              input {
                width: 100%;
                padding: 10px;
                border: 1px solid #e6e6e6;
                @include Font(16, 400, 3);
                @include BorRadius(25px);
              }
              textarea {
                @extend input;
                resize: none;
                height: 150px;
              }
              .EmailBtn {
                padding: 10px 50px;
                border: 0;
                background: nth($color, 5);
                @include BorRadius(25px);
                @include Font(18, 400, 1);
              }
            }
          }
        }
        .BtnDiv {
          button {
            padding: 10px 50px;
            background: nth($color, 5);
            border: 0;
            margin: 0 15px;
            @include Font(18, 400, 1);
            @include BorRadius(25px);
            @include Tran(0.4s, ease-in-out);
            &:hover {
              background: #8e1046;
            }
          }
        }
        .ModalInnerDiv {
          position: relative;
          .ModalText {
            text-align: center;
            h6 {
              @include Font(20, 600, 4);
              margin-bottom: 10px;
            }
            p {
              @include Font(16, 400, 3);
              margin-bottom: 30px;
              span {
                @include Font(16, 600, 4);
              }
            }
            img {
              display: block;
              margin: 0 auto 30px;
            }
            .TextUl {
              width: 80%;
              float: none;
              margin: 0 auto;
              li {
                margin-bottom: 15px;
                @include Font(14, 400, 3);
                span {
                  margin-right: 10px;
                  @include Font(16, 400, 4);
                }
              }
            }
          }
          .ModalBtnDiv {
            button {
              padding: 10px 50px;
              background: nth($color, 5);
              border: 0;
              margin: 0 15px;
              @include Font(18, 400, 1);
              @include BorRadius(25px);
              @include Tran(0.4s, ease-in-out);
              &:hover {
                background: #8e1046;
              }
            }
            a {
              @extend button;
              padding: 10px 30px;
              @include Font(16, 400, 1);
              display: inline-block;
            }
          }
        }
      }
    }
  }
}
